import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import { Icon } from '@iconify/vue2'
import Toast from "vue-toastification"
import Header from "./components/Header"
import "vue-toastification/dist/index.css"
import './plugins/quillEditor'

Vue.config.productionTip = false
Vue.component('Icon', Icon)
Vue.component('Header', Header)
Vue.use(Toast, {
  position: "bottom-left",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
})

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
