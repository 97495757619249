<template lang="pug">
.grid
  v-row(
    no-gutters align="center"
    justify="center"
    )
    v-col(
      :cols="$vuetify.breakpoint.mdAndUp ? 8 : 11"
      )
      .d-flex.flex-row(erroEmail
        :class="$vuetify.breakpoint.mdAndUp ? 'justify-start' : 'justify-center'"
        )
        v-img.mb-12(
          src="../assets/logo-color-horizontal.svg"
          contain max-width="252px"
          )
      v-row(
        justify="center"
        align="start")
        v-col(
          cols=12
          )
          span.mb-5(
            v-if="messageGeral"
            style="color: #008894"
            ) Usuário autenticado com sucesso!
          v-text-field.mb-6(
            v-if="!borderColorUsername"
            label="E-mail"
            v-model="username"
            filled hide-details
            background-color="#F7F8FA"
            border-color=borderColorUsername
            border-bottom=solid   
            @keydown.enter="submit"       
            )
          v-text-field(
            v-else error
            label="E-mail"
            v-model="username"
            filled hide-details
            background-color="#F7F8FA"
            border-color=borderColorUsername
            border-bottom=solid     
            )
          span.mb-6(
            class="ml-3"
            v-if="messageGeralErro"
            style=style="color: #FF5252; font-size: 12px;"
            ) E-mail ou senha incorreta. Tente novamente.
          span.mb-5(
            style=style="color: #FF5252; font-size: 12px;"
            ) {{erroEmail}}
          v-text-field(
            v-if="!borderColorPassword"
            label="Senha"
            v-model="password"
            filled hide-details
            background-color="#F7F8FA"
            border-bottom=solid
            type="password"
            @keydown.enter="submit"
            )
          v-text-field(
            v-else
            error
            label="Senha"
            v-model="password"
            filled hide-details
            background-color="#F7F8FA"
            border-bottom=solid
            type="password"
            )
          span.mb-5(
            style=style="color: #FF5252; font-size: 12px;"
            ) {{erroPassword}}
          v-checkbox(
            label="Mantenha-me conectado"
            v-model="keepConnected"
            )
          v-btn(
            color="secondary darken-2"
            block height=40
            elevation=0
            @click="submit"
            ) Entrar
          .my-8.custom-divider
          //- =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
          //- BOTÃO ENTRAR COM O GOOGLE 
          //-   .px-2.custom-divider-text ou
          //- v-btn(
          //-   color="#EDEEF2"
          //-   block outlined height=40
          //-   elevation=0
          //-   ) 
          //-   v-img.mr-2(
          //-     src="../assets/google_g_logo.svg"
          //-     contain max-width="24px"
          //-     )
          //-   div.analise-text-bold(
          //-     style="color: #005A73 !important"
          //-     ) Entrar com Google
          //- =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
          .d-flex.flex-row.justify-center
            div.text-center(
              style="color: #005A73; cursor: pointer"
              @click.stop="$router.push('recover')"
              ) Esqueceu sua senha?
  div(
    :style="gradientStyle"
    v-if="$vuetify.breakpoint.smAndUp")
</template>

<script>
import { mapState } from 'vuex'
import Services from '../services/services'

export default {
  name: 'Login',
  data: () => ({
    username: '',
    password: '',
    keepConnected: false,
    borderColorUsername: false,
    borderColorPassword: false,
    messageGeral: false,
    messageGeralErro: false,
    solid: "solid",
    erroEmail: "",
    erroPassword: "",
    user: null
  }),
  components: {},
  computed: {
    ...mapState('login', ['form']),
    gradientStyle() {
      return {
        backgroundImage: `url(${require('../assets/gradient.png')})`,
        backgroundRepeat: "round",
        backgroundAttachment: "fixed",
        backgroundSize: "cover"
      }
    }
  },
  methods: {
     validEmail(email){
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    async submit() {
      this.erroEmail = ""
      this.erroPassword = ""
      this.borderColorPassword = false
      this.borderColorUsername = false
      this.messageGeral = false
      this.messageGeralErro = false

      if(!this.validEmail(this.username)){
        this.erroEmail = "Por favor, insira um E-mail válido."
        this.borderColorUsername = true
      }else if(this.username.length > 4 && this.password.length > 6){
        try {
          this.user = await Services().login({'email': this.username,'password': this.password})
          this.messageGeral = true
        } catch (e) {
          this.messageGeralErro = true
          this.borderColorPassword = true
          this.borderColorUsername = true
        }
      // }else if(this.username.length < 4 && this.password.length > 6){
      //   this.erroEmail = "E-mail incorreto. Tente novamente."
      //   this.borderColorUsername = true
      // }else if(this.username.length > 4 && this.password.length <= 6){
      //   this.erroPassword = "Senha incorreta. Tente novamente."
      //   this.borderColorPassword = true
      // }else if(this.username.length < 4 && this.password.length <= 6){
      //   this.erroEmail = "E-mail incorreto. Tente novamente."
      //   this.erroPassword = "Senha incorreta. Tente novamente."
      //   this.borderColorPassword = true
      //   this.borderColorUsername = true
      }
      
      // usar localStorage para salvar a response; está nomeado como accessToken  
      //400 Faltando dados 
      //401 Não autorizado
    }
  }
}
</script>

<style lang="sass" scoped>
.grid
  display: grid
  grid-auto-flow: column
  grid-auto-columns: 1fr
  height: 100%

.custom-divider
  display: flex
  flex-wrap: nowrap
  justify-content: flex-start
  align-items: center
  &-text
    text-color: #EDEEF2 !important
    opacity: 0.64
    font-family: Metropolis
    font-style: normal
    font-size: 16px
  &::before,
  &::after
    content: ""
    flex: 1 1 auto
    border-bottom: 2px solid #EDEEF2
</style>