import { getField, updateField } from 'vuex-map-fields'
import Services from '../../../services/services'

export const state = {
  list: [],
  neverListed: true
}

export const getters = {
  getField
}
export const actions = {
  // eslint-disable-next-line
  async list({ commit, dispatch, state, rootState }, { force = false, ...params } = {}) 
  {
    if (state.neverListed || force) {
      await new Promise((resolve) => setTimeout(resolve, 1500))
      commit('setList', await Services().themes_list())
      commit('setNeverListed', false)
    }
  }
}
export const mutations = {
  updateField,
  setList(state, data) {
    state.list = data
  },
  setNeverListed(state, data) {
    state.neverListed = data
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}