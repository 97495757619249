<template lang="pug">
div
  Header(:title="activity.theme || ''" backBtn @clickSecondaryBtn="showTexts = true" secondaryBtn secondaryBtnText="Textos motivadores" secondaryBtnIcon="ic:outline-visibility")
  v-divider
  v-tabs.hidden-md-and-up
    v-tab Correção
    v-tab Redação  
    v-tab-item
      v-col.hidden-md-and-up(cols=12)
        .pa-8(style="background-color: #FFFFFF;")
          v-divider
          v-row(justify="space-between" align="center")
            v-col(cols="auto")
              .analise-subtitle-xs.my-8 Nota geral
            v-col(cols="auto")
              v-chip(label x-large color="#F2FCFF")
                .analise-bold(style="color: #005A73") {{nota_geral}}
          v-divider
          .analise-sbutitle-xs.my-6.analise-bold(style="color: #005A73") Detalhamento das competências
          v-row(no-gutters justify="space-between" align="center")
          v-divider
          br
          v-card(v-if="skills.message" width="465px" height="100px" outlined :color="'#FEF2F2'")
            v-card-title.primary--text(class="pa-2")
              .headline.analise-bold(style="color: #920000; font-size: 16px; font-weight: 500;") {{ message[0] }}
              .subtitle-2(style="color: #403F4C; font-weight: 400;") {{ message[1] }}
        v-expansion-panels(
          class="font-weight-black"
          v-model="panel"
          :disabled="disabled"
          multiple
        )
          v-expansion-panel(
            elevation="0"
            rounded="0"
            class="accordion-base"
            v-for="(skill, index) in skills.skills" :key="index"
          )
            v-expansion-panel-header 
              .analise-subtitle-xs.my-8.mr-3(
                class="font-weight-regular"
                style="display: none;"
                )
              
              span(class="title") {{skill.title}}
            
              span(class='nota') Nota 
              v-chip(label x-large color="#F7F8FA" style="min-width: 60px;" class="nota-skill")
                  
                    |  {{skill.nota}}
              template(v-slot:actions class="test")
                v-icon(class="icon") $expand
            v-expansion-panel-content(
              class="font-weight-regular"
            )
              | {{skill.explicacao}}    
    v-tab-item
      v-row.hidden-md-and-up(justify="center" no-gutters style="background-color: #F7F8FA; height: 88.8vh")
        v-col(cols=12)
          v-container.mt-10
            v-row(justify="center")
              v-col
                v-card.pa-10(elevation=0)
                  v-skeleton-loader(v-if="loading" type="text@10, paragraph")
                  div(v-html="activity.status != 'waiting' ? this.skills.correction : this.skills.text")


            //- //===============DESKTOP ABAIXO=====================

  v-divider
  v-row.hidden-sm-and-down(justify="center" no-gutters style="background-color: #F7F8FA; height: 88.8vh")
    v-col(cols=7)
      v-container.mt-10
        v-row(justify="center")
          v-col
            v-card.pa-10(elevation=0)
              v-skeleton-loader(v-if="loading" type="text@10, paragraph")
              div(v-html="activity.status != 'waiting' ? this.skills.correction : this.skills.text")
    v-col.hidden-sm-and-down(cols=5)
      .pa-8(style="background-color: #FFFFFF;")
        .analise-heading-xs.mb-6(style="color: #005A73") Correção
        //- v-divider
        //- v-row(justify="space-between" align="center")
        //-   v-col(cols="auto")
        //-     .analise-subtitle-xs.my-8 Nota geral
        //-   v-col(cols="auto")
        //-     v-chip(label x-large color="#FEF2F2")
        //-       .analise-bold(style="color:#670000") {{nota_geral}}
        //- v-divider
        v-divider
        v-row(justify="space-between" align="center")
          v-col(cols="auto")
            .analise-subtitle-xs.my-8 Nota geral
          v-col(cols="auto")
            v-chip(label x-large color="#F2FCFF")
              .analise-bold(style="color: #005A73") {{nota_geral}}
        v-divider
        .analise-sbutitle-xs.my-6.analise-bold(style="color: #005A73") Detalhamento das competências
        v-row(no-gutters justify="space-between" align="center")
        v-divider
        br
        v-card(v-if="skills.message" width="465px" height="getHeight" outlined :color="skills.message === 'Redação em processamento. Em breve haverá o retorno.' ? '#F2FCFF' : '#FEF2F2'")
          v-card-title.my-2.analise-bold(style="font-size: 16px")
            .headline.analise-bold(:style="{color: skills.message === 'Redação em processamento. Em breve haverá o retorno.' ? '#005A73' : '#920000'}") {{ message[0] }}
            .subtitle-2(style="color: #403F4C; font-weight: 400;") {{ message[1] }}

        v-expansion-panels(
          class="font-weight-black"
          v-model="panel"
          :disabled="disabled"
          multiple
        )
          v-expansion-panel(
            elevation="0"
            rounded="0"
            class="accordion-base"
            v-for="(skill, index) in skills.skills" :key="index"
          )
            v-expansion-panel-header 
              .analise-subtitle-xs.my-8.mr-3(
                class="font-weight-regular"
                style="display: none;"
                )
              
              span(class="title") {{skill.title}}
            
              span(class='nota') Nota 
              v-chip(label x-large color="#F7F8FA" style="min-width: 60px;" class="nota-skill")
                  
                    |  {{skill.nota}}
              template(v-slot:actions class="test")
                v-icon(class="icon") $expand
            v-expansion-panel-content(
              class="font-weight-regular"
            )
              | {{skill.explicacao}}     
  v-navigation-drawer.no-shadow(
    v-model="showTexts"
    app
    right
    overlay-opacity="0.5"
    temporary
    width=610
  )
    .analise-heading-md.mt-1.pa-8
      span(v-if="!loading") {{ activity.theme }}
      v-skeleton-loader(v-if="loading" type="chip")
    v-chip.ml-6(label small color="#F2FCFF")
      .analise-bold(style="color: #005A73") {{ activity.category }}
    div.pt-6.pa-8
      v-skeleton-loader(v-if="loading" type="text@31, paragraph")
      div(v-if="!loading" v-html="activity.body")
      br
      h3(v-if="!loading") TEXTO I
      p(v-if="!loading") {{activity.text_1}}
      h3(v-if="!loading") TEXTO II
      p(v-if="!loading") {{activity.text_2}}
      h3(v-if="!loading") TEXTO III
      p(v-if="!loading") {{activity.text_3}}
</template>

<script>
import Services from '../../services/services'

export default {
  data() {
    return {
      tab: 'one',
      response_activity: [],
      loading: true,
      showTexts: false,
      sending: false,
      panel: [],
      disabled: false,
      skills: [],
      message: '',
      nota_geral: 0,
      nota: 0,
      text: '',
      correction: '',
      errors: [],
      listIndex: [],
      listMessageIndex: [],
      infoProfile: []
    }
  },
  computed: {
    activity() {
      const id = this.$route.params.id
      const activity = this.$store.getters['activities/getActivityById'](id)
      return activity || { theme: {} }
    },
    textParts() {
      const parts = []
      let lastError = null

      for (const error of this.errors) {
        const wordCount = ((error.end - error.start) + 1)
        
        const start = error.start - wordCount
        const end = error.end - wordCount

        const lastErrorEnd = lastError?.realEnd || 0
        if (!lastErrorEnd < start) {
          parts.push({
            text: this.text.split(' ').slice(lastErrorEnd, start).join(' ')
          })
        }

        const errorPart = {
          text: this.text.split(' ').slice(start, end).join(' '),
          error: {
            ...error,
            realStart: start,
            realEnd: end
          }
        }
        lastError = errorPart.error
        parts.push(errorPart)
      }

      const lastErrorEnd = lastError?.realEnd || 0
     
      const finalPart = this.text.split(' ').slice(lastErrorEnd, this.text.length).join(' ')

      if (finalPart) {
        parts.push({ text: finalPart })
      }

      return parts
    }
  },
  methods: { },
  async created() {
    const id = this.$route.params.id
    await this.$store.dispatch('activities/list/' +id+ '/')
  },
  async mounted() {
    // const id = this.$route.params.id
    await Services().profile().then((res) => { this.infoProfile = res })
    
    await Services().activie_id(this.$route.params.id).then((res) => { this.skills = res })
    // this.skills = this.$route.params.skills

    // this.nota = this.skills.skills.nota

    this.nota_geral = this.skills.general_grade

    this.text = this.skills.text
    this.correction = this.skills.correction.split(" ")

    if( this.skills.message[17] === '.' || this.skills.message[24] === '.'){
      this.message = this.skills.message.split(".")
    }
    else if( this.skills.message[7] === '!'){
      this.message = this.skills.message.split("!")
    }
    // this.message = this.skills.message.split(".")
  
    this.errors = this.skills.errors
    
    this.errors.forEach(element => {
      this.listIndex.push(element.start)
      this.listMessageIndex.push({id: element.start, message: element.message})
    });
    const erroTags = document.querySelectorAll('erro');
    for (let i = 0; i < erroTags.length; i++) {
    erroTags[i].classList.add('errorTag');
  }
  const erros = document.querySelectorAll('erro');
  erros.forEach(erro => {
    erro.addEventListener('mouseover', (event) => {
      event.target.title = '';
    });
  });
  const errors = this.$el.querySelectorAll('.errorTag')

  errors.forEach(error => {
        const title = error.getAttribute('title')
        const suggestions = error.getAttribute('suggestions')
        const tooltip = document.createElement('div')
        error.style.backgroundColor = '#F2FCFF';
        error.style.color = '#005A73';
        tooltip.innerText = "Titulo: " + title + " | Sugestao: "+ suggestions
        tooltip.style.display = 'none'
        tooltip.style.position = 'absolute'
        tooltip.style.backgroundColor = '#000'
        tooltip.style.color = '#fff'
        tooltip.style.padding = '5px'
        tooltip.style.borderRadius = '5px'
        error.appendChild(tooltip)
        error.addEventListener('mouseenter', () => {
          tooltip.style.display = 'block'
        })
        error.addEventListener('mouseleave', () => {
          tooltip.style.display = 'none'
        })
        const span = document.createElement('span')
        error.appendChild(span)
      })
    this.loading = false

    // chamar aqui uma função que pegue os skills/feedbacks de avaliação da atividade
    // pelo id da atividade... para evitar de ficar salvando dados no storage do navegador.
    // Exemplo:
    //Service().service_get_skills().then((res) => { this.skills = res.skills })
  }
}
</script>

<style scoped>
.no-shadow {
  box-shadow: none !important;
}

.analise-error {
  color: #005A73;
  background: #F2FCFF;
}

.error {
  color: #F2FCFF !important;
}

.errorTag{
  background-color: #F2FCFF;
  color: #005A73;
}

.v-expansion-panel::before {
  box-shadow: none;
}

.v-expansion-panel-header {
  display: flex;
  flex-direction: row !important;
  justify-content:flex-start !important;
  align-items: center !important;
  padding: 0px !important;
  gap: 16px !important;
  margin-top: 24px !important;
  margin-bottom: 24px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;

}

.icon {
  order: 0;
  width: 10px;
}

.title {
  order: 1;
  max-width: 256px;
  font-family: 'Rubik' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  margin-right: 56px;
}

.nota {
  order: 2;
}

.nota-skill {
  order: 3;
  width: 47px !important;
  height: 40px !important;
  display: flex;
}

</style>