<template lang="pug">
  v-card.pa-4(v-if="!deleted")
    .d-flex.flex-row.justify-space-between.align-center
      .d-flex.flex-column
        .analise-heading-xs.analise-primary-400-text Comentário
      .d-flex.flex-column
        v-btn.rounded(:ripple="false" icon @click="$emit('close')")
          v-icon mdi-close
    .d-flex.flex-row.justify-space-between.align-center
      .d-flex.flex-column
        .analise-subtitle-xs(style="font-size: 14px") Tipo de erro
      .d-flex.flex-column
        v-chip(label small color="#F2FCFF")
          .analise-bold(style="color: #005A73") Semântico
    .my-4
      v-text-field(:disabled="!editing" filled v-model="form.message" background-color="#F7F8FA" hide-details="Auto" label="Comentário")
    v-row
      v-col(cols=6)
        v-btn(text block @click="$emit('deleteError'), deleted = true")
          Icon.mr-2(width="20px" icon="ic:outline-delete")
          .analise-bold Excluir
      v-col(cols=6)
        v-btn(v-if="!editing" text outlined block @click="editing = true")
          Icon.mr-2(width="20px" icon="ic:outline-edit")
          .analise-bold Editar
        v-btn(v-else text outlined block @click="$emit('input', form), editing = false")
          Icon.mr-2(width="20px" icon="ic:outline-save")
          .analise-bold Salvar
</template>

<script>
export default {
  props: ['value'],
  data: () => ({
    deleted: false,
    editing: false,
    form: {}
  }),
  computed: {
    error: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  },
  created() {
    this.form = { ...this.error }
  }
}
</script>