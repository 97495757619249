import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Classes from '../views/Classes.vue'
import Users from '../views/Users.vue'
import Themes from '../views/Themes.vue'
import ThemesStudent from '../views/ThemesStudent.vue'
import ThemeCreator from '../pages/themes/ThemeCreator.vue'
import Activities from '../views/Activities.vue'
import ActivitiesManager from '../views/ActivitiesManager.vue'
import ActivityManager from '../views/ActivityManager.vue'
import ActivityOverview from '../pages/activity/ActivityOverview.vue'
import WriteActivity from '../pages/activity/WriteActivity.vue'
import ActivityManageCorrection from '../views/ActivityManageCorrection.vue'
import Feedback from '../pages/activity/Feedback'
import pendingactivies from '../pages/activity/pendingactivies.vue'

import Login from '../pages/Login.vue'
import Logout from '../pages/Logout.vue'
import RecoverPassword from '../pages/RecoverPassword.vue'
import NewPassword from '../pages/NewPassword.vue'

import store from '../store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { requiresAuth: true },
    },

    //Auth
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { showDrawer: false },
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout,
        meta: { showDrawer: false },
    },
    {
        path: '/recover',
        name: 'Recuperar Senha',
        component: RecoverPassword,
        meta: { showDrawer: false },
    },
    {
        path: '/newpassword/:uidb64/:token/',
        name: 'Nova Senha',
        component: NewPassword,
        meta: { showDrawer: false },
    },

    // Student
    {
        path: '/activities',
        name: 'Activities',
        component: Activities,
        meta: { requiresAuth: true },
    },
    {
        path: '/pendingactivies',
        name: 'pendingactivies',
        component: pendingactivies,
        meta: { requiresAuth: true },
    },
    {
        path: '/activitiesmanager',
        name: 'ActivitiesManager',
        component: ActivitiesManager,
        meta: { requiresAuth: true },
    },
    {
        path: '/themes',
        name: 'Themes',
        component: Themes,
        meta: { requiresAuth: true },
    },
    {
        path: '/themesstudent',
        name: 'ThemesStudent',
        component: ThemesStudent,
        meta: { requiresAuth: true },
    },
    {
        path: '/themes/create',
        name: 'ThemeCreator',
        component: ThemeCreator,
        meta: { requiresAuth: true },
    },
    {
        path: '/activity/:id',
        name: 'ActivityOverview',
        component: ActivityOverview,
        meta: { requiresAuth: true },
    },
    {
        path: '/activity/:id/submit/write/',
        name: 'WriteActivity',
        component: WriteActivity,
        meta: { showDrawer: false, requiresAuth: true },
    },
    {
        path: '/activity/:id/feedback/',
        name: 'ActivityFeedback',
        component: Feedback,
        meta: { showDrawer: false, requiresAuth: true },
    },
    {
        path: '/activity/:id/manage',
        name: 'ActivityManager',
        component: ActivityManager,
    },
    {
        path: '/activity/:id/manage',
        name: 'ActivityManageCorrection',
        component: ActivityManageCorrection,
        meta: { showDrawer: false, requiresAuth: true },
    },

    // Manage
    {
        path: '/manage/classes',
        name: 'Classes',
        component: Classes,
        meta: { requiresAuth: true },
    },
    {
        path: '/manage/users',
        name: 'Users',
        component: Users,
        meta: { requiresAuth: true },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (localStorage.getItem('accessToken') == null) {
            next({
                path: '/login',
                params: { nextUrl: to.fullPath },
            })
        } else {
            // mudar o codigo se ele não retornar o usuário na response de login
            // o ideial é passa isso para o localStorage (a role do usuario)
            // pode usar meta: { is_admin: true } na rota
            let user = JSON.parse(localStorage.getItem('user'))
            if (to.matched.some((record) => record.meta.is_admin)) {
                if (user.is_admin == 1) {
                    next()
                } else {                    
                    next({ name: 'Home' })
                }
            } else {                
                next()
            }
        }
    } else if (to.matched.some((record) => record.meta.guest)) {
        if (localStorage.getItem('accessToken') == null) {
            next()
        } else {
            next({ name: 'Home' })
        }
    } else {
        next()
    }
})

router.afterEach((to) => {
    store.dispatch('route/updateMeta', to.meta)
})

export default router
