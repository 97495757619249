<template lang="pug">
div
  Header(title="Gerenciar Usuários" primaryBtn secondaryBtn primaryBtnText="Convidar usuário" secondaryBtnText="Importar Usuários" secondaryBtnIcon="ic:outline-download")
  v-divider
  .pa-6
    v-row.analise-filters
      v-col(cols=3 xs=6)
        v-text-field(label="Procurar pelo nome de usuário" filled hide-details="auto" background-color="#F7F8FA")
          template(v-slot:append)
            Icon(icon="ic:outline-search" width="24px" height="24px")
      v-col(cols=2 xs=6)
        v-autocomplete(:items="filters" item-value="id" item-text="title" label="Filtre por cargo" filled hide-details="auto" background-color="#F7F8FA")
    v-card.mt-6(outlined)
      v-data-table.analise-data-table(show-select :page.sync="table.page" :headers="table.headers" outlined :items-per-page="table.itemsPerPage" disable-sort hide-default-footer :items="users.list" :loading="table.loading")
        template(v-slot:item.type.title="{ item }")
          v-menu
            template(v-slot:activator="{ on, attrs }")
              v-row(no-gutters align="center" v-on="on" v-bind="attrs")
                v-col(cols="auto")
                  div {{ item.type.title }}
                v-col(cols="auto")
                  Icon.ml-1.mt-1(icon="ic:outline-keyboard-arrow-down" width="16px" height="16px")
            v-list
              v-list-item Aluno
              v-list-item Professor
              v-list-item Gestor
        template(v-slot:item.actions)
          v-btn(icon)
            Icon(icon="ic:outline-info" width="24px" height="24px")
          v-btn(icon)
            Icon(icon="ic:outline-edit" width="24px" height="24px")
          v-btn(icon color="error")
            Icon(icon="ic:outline-delete" width="24px" height="24px")
    v-row(justify="end")
      v-col(cols="auto")
        v-pagination.pt-4(v-model="table.page" :length="paginationLength" color="#005A73" :disabled="table.loading")

</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      filters: [
        {
          title: "Aluno",
          id: 1
        },
        {
          title: "Professor",
          id: 2
        },
        {
          title: "Gestor",
          id: 3
        },
      ],
      table: {
        page: 0,
        itemsPerPage: 10,
        loading: false,
        headers: [
          {
            text: 'Nome',
            value: 'name'
          },
          {
            text: 'Cargo',
            value: 'type.title'
          },
          {
            align: 'end',
            width: '25%',
            text: 'Ações',
            value: 'actions'
          },
        ]
      }
    }
  },
  computed: {
    ...mapState(['users', ['list']])
  },
  async created() {
    this.table.loading = true
    await this.$store.dispatch('users/list')
    this.table.loading = false
  }
}
</script>