import axios from 'axios'

const openhttp = axios.create({
    baseURL: process.env.VUE_APP_API_BASE,
    timeout: 15000,
})

const requestHandler = (request) => {
    
    return request
}

const responseHandler = (response) => {

    return response
}

const errorHandler = (error) => {
    return Promise.reject(error)
}

 openhttp.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
)

openhttp.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
)

export default openhttp
