<template lang="pug">
.grid
  v-row(v-if="loading" class="d-flex justify-center mb-6 align-center",)
      v-progress-circular(
      :size="70",
      :width="7",
      color="#005A73",     
      indeterminate
)
  v-row(
    no-gutters align="center"
    justify="center"
    v-else
    )
    v-col(
      cols=8
      )
      v-row(
        justify="center"
        align="start"
        )
        v-col
          v-img(
            src="../assets/logo-color-horizontal.svg"
            contain max-width="252px"
            )
        v-col(
          cols=12
          )          
          v-btn.mb-5.mt-8(
            color="secondary darken-4"
            text outlined max-width="110px"
            @click.stop="$router.push('/')"
            )
            v-icon(
              left
              ) mdi-arrow-left
            | Voltar
          v-window.mt-6(
            v-model="step"
            )
            v-window-item
              h3.analise-heading-sm.primary--text.text--darken-3 Cadastrar nova senha
              span.analise-subtitle-sm
              <p v-if="passwordInvalid"> A senha deve ter ao menos oito caracteres, sendo ao menos uma letra maiúscula, uma minúscula, um número e um caractere especial.</p>
              v-text-field(   
                :class="passwordsDoNotMatch ? 'error' : 'mb-6'"
                :error="passwordsDoNotMatch ? true : false"      
                v-if="!borderColorPassword"
                label="Nova senha"
                v-model="password"
                filled hide-details
                background-color="#F7F8FA"
                border-color=borderColorPassword
                border-bottom=solid
                type="password"
                @keydown.enter="submit"
                )
              span(
                class="ml-3"
                v-if="passwordsDoNotMatch"
                style="color: #FF5252; font-size: 12px;"
                ) {{errorPassword}}
              v-text-field(
                v-if="!borderColorPassword"
                :error="passwordsDoNotMatch ? true : false"    
                label="Confirmar nova senha"
                v-model="conf_password"
                filled hide-details
                background-color="#F7F8FA"
                border-bottom=solid
                type="password"
                @keydown.enter="submit"
                )
              v-btn.mt-6(
                color="secondary darken-2"
                large
                @click="submit"
                :loading="loading"
                block
                ) Cadastrar nova senha              
            v-window-item
              v-row(
                no-gutters justify="center"
                )
                v-col(
                  cols="auto"
                  )
                  v-avatar(
                    color="#F2FCFF"
                    size="104px"
                    )
                    v-icon(
                      color="primary darken-3"
                      x-large
                      ) mdi-check
              v-row(
                no-gutters justify="center"
                )
                v-col(
                  cols="auto"
                  )
                  h3.mt-9.analise-heading-sm.primary--text.text--darken-3 Senha alterada com sucesso.
              v-row(
                no-gutters justify="center"
                )
                v-col(
                  cols="auto"
                  align="center"
                  )
                  p.analise-subtitle-sm
                    | Entre com as novas credenciais.
                  v-btn.mt-12(
                    color="secondary darken-2"
                    large
                    @click="$router.push('/')"
                    block
                    ) Entrar                     
            v-window-item
              v-row(
                no-gutters justify="center"
                )
                v-col(
                  cols="auto"
                  )
                  v-avatar(
                    color="#F2FCFF"
                    size="104px"
                    )
                    v-icon(
                      color="primary darken-3"
                      x-large
                      ) mdi-link-off
              v-row(
                no-gutters justify="center"
                )
                v-col(
                  cols="auto"
                  )
                  h3.mt-9.analise-heading-sm.primary--text.text--darken-3 Ops, link expirado...
              v-row(
                no-gutters justify="center"
                )
                v-col(
                  cols="auto"
                  align="center"
                  )
                  p.analise-subtitle-sm
                    | Parece que já faz algum tempo que esse link foi gerado. Solicite novamente a troca de senha.
                  v-btn.mt-12(
                    color="secondary darken-2"
                    large
                    @click="$router.push('/recover')"
                    block
                    ) Solicitar novamente
                  v-btn.mt-12(
                    color="secondary darken-2"
                    large
                    outlined
                    @click="$router.push('/')"
                    block
                    style="border: 1px solid #EDEEF2;"
                    ) Voltar para página inicial
  div(:style="gradientStyle" v-if="$vuetify.breakpoint.smAndUp")
</template>

<script>
import { mapState } from 'vuex'
import Services from '../services/services'

export default {
  name: 'newpassword/',
  data: () => ({
    uidb64: '',
    token: '',
    password: '',
    conf_password: '',
    errorPassword: "",
    step: 0,
    Invalid: false,
    borderColorPassword: false,
    loading: false,
    passwordsDoNotMatch: false
  }),
  components: {},
  computed: {
    ...mapState('login', ['form']),
    gradientStyle() {
      return {
        backgroundImage: `url(${require('../assets/gradient.png')})`,
        backgroundRepeat: "round",
        backgroundAttachment: "fixed",
        backgroundSize: "cover"
      }
    }
  },
  methods: {
  passwordInvalid(password) {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()\-_=+[\]{}\\|;:'",.<>/?~`]).{8,}$/
    return passwordRegex.test(password)
  },
   async valid() {
      let url = 
      window.location.href
      let test = url.split('/')
      let uidb64 = test[4]
      let token = test[5]

      this.user = await Services().token_reset(
        uidb64,
        token
      )

      if(!this.user.data.success || this.user.status === 400 || this.user.status === 401 || this.user.status === 500){
        this.step = 2
      }

      this.loading = false
    },
    async submit() {
      this.loading = true 
      this.errorPassword = ""
      this.borderColorPassword = false
      this.messageGeral = false
      this.passwordsDoNotMatch = this.password !== this.conf_password
      !this.password || this.password === ''? this.Invalid = false : this.Invalid = true

      if(this.passwordInvalid(this.password) === false){
        this.errorPassword = "A senha não atende aos requisitos."
        this.borderColorPassword = false
        return
      }
    

      if(this.Invalid) {
        this.borderColorPassword = true
        this.errorPassword = ""
      }
      
      
      this.user = await Services().new_password({
        'password': this.password,
        'conf_password': this.conf_password,
        'token': window.localStorage.getItem('token'), 
        'uidb64': window.localStorage.getItem('uidb64')        
      })

      // retornar erro correto do back
      if(this.user.status === 200 && !(this.user.data.error === "Passwords must be the same")){
        this.step = 1
      } else {
        this.borderColorPassword = true
        this.errorPassword = "As senhas devem ser iguais."
      }
      this.loading = false
     }
  },
  mounted() {
    this.valid()
  }
}
</script>

<style lang="sass" scoped>
.grid
  display: grid
  grid-auto-flow: column
  grid-auto-columns: 1fr
  height: 100%
</style>