<template lang="pug">
  div
    Header(title="Atividade" backBtn primaryBtn primaryBtnIcon="ic:outline-create" primaryBtnText="Editar" secondaryBtn secondaryBtnIcon="ic:outline-delete" secondaryBtnText="Excluir")
    v-divider
    .pa-8
      v-row(justify="space-between")
        v-col(cols="auto")
          .analise-heading-xs.analise-bold(style="color: #005A73") {{ themeName }}
          .py-6
            v-row(justify="space-between")
              v-col.py-1(cols="auto")
                .analise-bold(style="color: #403F4C") Data de entrega
              v-col.py-1(cols="auto")
                div(style="color: #005A73") até as 23:59 de {{ activity.date }}
            v-row(justify="space-between")
              v-col.py-1(cols="auto")
                .analise-bold(style="color: #403F4C") Postada em
              v-col.py-1(cols="auto")
                div(style="color: #005A73") {{ activity.createdAt }}
        v-col(cols="auto")
          v-card(outlined)
            .pa-4
              v-row(justify="space-between")
                v-col(cols="auto")
                  div(style="color: #403F4C") Total de submissões
                v-col(cols="auto")
                  .analise-bold.analise-heading-xs(style="color: #005A73") 3/45
              v-row(justify="space-between")
                v-col(cols="auto")
                  div(style="color: #403F4C") Nota média da turma
                v-col(cols="auto")
                  .analise-bold.analise-heading-xs(style="color: #005A73") 780
    v-divider
    .pa-8
      v-row(justify="space-between")
        v-col(cols="auto")
          .analise-heading-xs(style="color: #005A73") Subsmissões
        v-col(cols="auto")
          v-btn.rounded.mr-2(elevation=0 text @click="$emit('clickSecondaryB›tn')" :ripple="false")
            Icon(width=16 color="black" icon="ic:outline-send").mr-2
            .black--text Enviar correções
      v-row.analise-filters
        v-col(cols=6)
          v-text-field(v-model="filters.name" label="Procurar pelo nome da turma" filled hide-details="auto" background-color="#F7F8FA")
            template(v-slot:append)
              Icon(icon="ic:outline-search" width="24px" height="24px")
        v-col(cols=3)
          v-autocomplete(:items="classes.list" item-value="id" item-text="name" label="Turma" filled hide-details="auto" background-color="#F7F8FA")
        v-col(cols=3)
          v-autocomplete(:items="statusItems" clearable item-value="value" item-text="name" label="Status" filled hide-details="auto" background-color="#F7F8FA")
      v-card.mt-6(outlined)
        v-data-table.analise-data-table(@click:row="handleClick" :page.sync="table.page" :headers="table.headers" outlined :items-per-page="table.itemsPerPage" disable-sort hide-default-footer :items="items" :loading="table.loading")
          template(v-slot:item.class="{ item }")
            div 3ª ano A - Médio
            //- TODO: Alterar para um endpoint que vem com tudo e remover os mocks
          template(v-slot:item.grade="{ item }")
            div -
            //- TODO: Alterar para um endpoint que vem com tudo e remover os mocks
          template(v-slot:item.status="{ item }")
            v-chip(label small :color="getStatusData(item.status).bgColor")
              .analise-bold(:style="{ color: getStatusData(item.status).color }") {{ getStatusData(item.status).name || 'Sem Dados' }}
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      filters: {
        name: null,
        class: null,
        status: null
      },

      statusItems: [
        {
          name: 'Enviada',
          value: 'sent',
          color: '#196416',
          bgColor: '#F4FDF4'
        },
        {
          name: 'Manual',
          value: 'manual',
          color: '#196416',
          bgColor: '#F4FDF4'
        },
        {
          name: 'Automática',
          value: 'auto',
          color: '#005A73',
          bgColor: '#F2FCFF'
        },
        {
          name: 'Submissão Pendente',
          value: 'pending',
          color: '#403F4C',
          bgColor: '#F7F8FA'
        }
      ],

      table: {
        loading: false,
        page: 1,
        itemsPerPage: 9,
        headers: [
          {
            text: 'Aluno',
            value: 'name'
          },
          {
            text: 'Turma',
            value: 'class'
          },
          {
            text: 'Correção',
            value: 'status'
          },
          {
            text: 'Nota',
            value: 'grade'
          }
        ]
      }
    }
  },
  async created() {
    await this.$store.dispatch('activities/list')
    await this.$store.dispatch('classes/list')
    await this.$store.dispatch('users/list')
  },
  computed: {
    ...mapState(['classes', ['list']]),
    ...mapState(['users', ['list']]),
    items() {
      return this.users.list
    },
    activity() {
      const id = this.$route.params.id
      const activity = this.$store.getters['activities/getActivityById'](id)
      return activity || {}
    },
    themeName() {
      return this.activity?.theme?.name || ""
    }
  },
  methods: {
    handleClick() {
      this.$router.push({ name: 'ActivityManageCorrection'})
    },
    getStatusData(status) {
      return this.statusItems.find(x => x.value === status) || {}
    }
  }
}
</script>