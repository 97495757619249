<template lang="pug">
  .pa-6
    v-row(no-gutters align="center")
      v-col(cols="auto" v-if="backBtn")
        v-btn.rounded.mr-6(icon style="border: 1px solid #EDEEF2" @click.stop="$router.go(-1)" :ripple="false")
          Icon(width=24 icon="ic:baseline-arrow-back")
      v-col(cols="auto" v-if="prependIcon").mr-2
        v-avatar(color="#F2FCFF" width=40 style="padding: 10px")
          Icon(color="#005A73" :icon="prependIcon")
      v-col(cols="auto")
        .py-0.my-0.analise-heading-md.secondary--text.text--darken-3(style="font-size: 20px") {{ title }}
        .py-0.my-0.grey--text(style="font-size: 14px !important" v-if="description") {{ description }}
      v-spacer
      v-col(cols="auto" v-if="secondaryBtn")
        v-btn.rounded.mr-2(elevation=0 text @click="$emit('clickSecondaryBtn')" :ripple="false")
          Icon(width=24 color="black" :icon="secondaryBtnIcon" v-if="secondaryBtnIcon").mr-2
          .black--text {{ secondaryBtnText }}
      v-col(cols="auto" v-if="primaryBtn")
        v-btn.rounded(color="#2D848E" elevation=0 @click="$emit('clickPrimaryBtn')" :ripple="false")
          Icon(width=24 color="white" :icon="primaryBtnIcon" v-if="primaryBtnIcon").mr-2
          .white--text {{ primaryBtnText }}
</template>

<script>
export default {
  props: {
    backBtn: {
      type: Boolean,
      default: false
    },
    primaryBtn: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      default: null
    },
    primaryBtnText: {
      type: String,
      default: 'Label'
    },
    primaryBtnIcon: {
      type: String,
      default: 'ic:baseline-add'
    },
    secondaryBtn: {
      type: Boolean,
      default: false
    },
    secondaryBtnText: {
      type: String,
      default: 'Label'
    },
    secondaryBtnIcon: {
      type: String,
      default: 'ic:baseline-add'
    },
    description: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: 'Título'
    }
  }
}
</script>