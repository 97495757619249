import { getField, updateField } from 'vuex-map-fields'

export const state = {
  list: [],
  neverListed: true
}

export const getters = {
  getField
}
export const actions = {
  // eslint-disable-next-line
  async list({ commit, dispatch, state, rootState }, { force = false, ...params } = {}) 
  {
    if (state.neverListed || force) {
      await new Promise((resolve) => setTimeout(resolve, 1500))
      commit('setList', [
        {
          id: 0,
          name: 'João Paulo Cavalcante Costa',
          email: 'email@dominio.com',
          type: {
            title: 'Aluno',
            id: 1
          },
          photo: null
        },
        {
          id: 1,
          name: 'Davi da Silva Moura',
          email: 'email@dominio.com',
          type: {
            title: 'Aluno',
            id: 1
          },
          photo: null
        },
        {
          id: 2,
          name: 'Murilo Ventura',
          email: 'email@dominio.com',
          type: {
            title: 'Aluno',
            id: 1
          },
          photo: null
        },
        {
          id: 3,
          name: 'Arthur Barros Lapprand',
          email: 'email@dominio.com',
          type: {
            title: 'Aluno',
            id: 1
          },
          photo: null
        },
        {
          id: 4,
          name: 'Jário José',
          email: 'email@dominio.com',
          type: {
            title: 'Aluno',
            id: 1
          },
          photo: null
        },
        {
          id: 5,
          name: 'Carla Silva',
          email: 'email@dominio.com',
          type: {
            title: 'Professor',
            id: 2
          },
          photo: null
        },
        {
          id: 5,
          name: 'Paulo Cavalcanti',
          email: 'email@dominio.com',
          type: {
            title: 'Gestor',
            id: 3
          },
          photo: null
        },
      ])
      commit('setNeverListed', false)
    }
  }
}
export const mutations = {
  updateField,
  setList(state, data) {
    state.list = data
  },
  setNeverListed(state, data) {
    state.neverListed = data
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}