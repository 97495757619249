<template lang="pug">
v-navigation-drawer(
  v-model="value"
  app
  right
  overlay-opacity="0.5"
  temporary
  stateless
  width=610
)
  .pa-4.pa-md-8
    v-row(no-gutters justify="space-between" align="center")
      v-col(cols="auto")
        .analise-heading-md.secondary--text.text--darken-3 Editar Turma
      v-col(cols="auto")
        v-btn(icon elevation=0 x-large @click.stop="$emit('input', false)")
          Icon(width="36px" height="36px" icon="ic:outline-close")
    v-divider.my-5
    v-window(v-model="step")
      v-window-item
        v-row(justify="space-between")
          v-col(cols="auto")
            .analise-heading-xs.analise-bold {{ form.name }}
            .analise-subtitle-xs {{ teacherName }}
          v-col(cols="auto")
            v-btn(icon @click.stop="step++")
              Icon(width="24" icon="ic:outline-edit")
        v-autocomplete.pt-4(v-model="form.student" :items="studentsList" return-object item-text="name" label="Adicionar Aluno" :append-icon="null" filled hide-details="auto" background-color="#F7F8FA")
          template(v-slot:append-outer)
            v-btn(icon small @click.stop="addStudent")
              Icon(width="24px" height="24px" icon="ic:outline-add")
        v-card.my-4(elevation=0 outlined)
          v-data-table(:headers="table.headers" hide-default-footer :items="form.studentsList")
            template(v-slot:item.name="{ item }")
              v-row(justify="space-between" align="center")
                v-col(cols="auto")
                  div {{ item.name }}
                v-col(cols="auto")
                  v-btn(icon color="#FF5252" small @click.stop="removeStudent(item.id)")
                    Icon(width="24px" icon="ic:outline-remove-circle")
      v-window-item
        .analise-heading-xs.analise-bold Descrição da turma
        v-text-field.pt-4(v-model="form.name" filled label="Nome" hide-details="auto" background-color="#F7F8FA")
        v-autocomplete.pt-4(v-model="form.teacherId" :items="teachers" item-value="id" item-text="name" label="Professor" filled hide-details="auto" background-color="#F7F8FA")
    .pb-7.px-4(style="position: absolute; bottom: 0; width: 90%")
      v-divider.mb-4
      v-row(justify="space-between")
        v-col(cols="auto")
          v-btn(text elevation=0 large @click.stop="step--" :disabled="step === 0")
            .analise-bold(style="font-size: 14px") Voltar
        v-col(cols="auto")
          v-btn(color="#2D848E" elevation=0 large @click.stop="next" :loading="loading")
            div(style="font-size: 14px; color: #FFF") {{ step === 0 ? "Salvar Alterações" : "Próximo" }}
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['value', 'classe'],
  name: 'ClassEditorDrawer',
  data() {
    return {
      loading: false,
      step: 0,
      form: {
        name: null,
        teacher: { name: '' },
        student: null,
        students: 0,
        studentsList: []
      },
      table: {
        headers: [
          {
            text: 'Alunos',
            value: 'name'
          }
        ]
      },
      teachersLoading: false
    }
  },
  mounted() {
    this.form = {
      ...this.classe,
      studentsList: []
    }
  },
  computed: {
    ...mapState(['users', ['list']]),
    teachers() {
      return this.users.list.filter(x => x.type.id === 2)
    },
    teacherName() {
      return (this.users.list.find(x => x.id === this.form.teacherId) || {}).name
    },
    students() {
      return this.users.list.filter(x => x.type.id === 1)
    },
    studentsList() {
      const selectedStudents = this.form?.studentsList
      return this.students.filter(function (x) {
        return !selectedStudents.find(y => y.id === x.id)
      })
    }
  },
  methods: {
    async next() {
      if (this.step === 1) {
        this.step = 0
      } else {
        this.loading = true
        
        const newData = {
          id: this.form.id,
          name: this.form.name,
          teacherId: this.form.teacherId,
          students: this.form.studentsList.length
        }

        await this.$store.dispatch("classes/update", newData)
        this.loading = false
        this.$toast.success("Alterações salvas com sucesso.")
        this.$emit('input', false)
        this.step = 0
      }
    },
    openImporter() {
      this.$emit('input', false)
      this.$emit('openImporter')
    },
    addStudent() {
      this.form.studentsList.push(this.form.student)
      this.form.student = null
    },
    removeStudent(id) {
      this.form.studentsList = this.form.studentsList.filter(x => x.id !== id)
    }
  },
  async created() {
    this.teachersLoading = true
    await this.$store.dispatch("users/list")
    this.teachersLoading = false
  }
}
</script>