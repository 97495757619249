<template lang="pug">
.grid
  v-row(no-gutters align="center" justify="center")
    v-col(cols=8)
      v-row(justify="center" align="start")
        v-col
          v-img(src="../assets/logo-color-horizontal.svg" contain max-width="252px")
        v-col(cols=12)
          v-btn.mb-5.mt-8(color="secondary darken-4" text outlined max-width="110px" @click.stop="$router.go(-1)")
            v-icon(left) mdi-arrow-left
            | Voltar
          v-window.mt-6(v-model="step")
            v-window-item
              h3.analise-heading-sm.primary--text.text--darken-3 Esqueceu sua senha?
              span.analise-subtitle-sm Vamos ajudá-lo a redefini-la. Enviaremos para o seu e-mail as instruções.
              v-text-field.mt-6(
                v-if="!borderColorEmail"
                label="E-mail cadastrado"
                v-model="email"
                filled hide-details
                background-color="#F7F8FA"
                border-bottom=solid
                @keydown.enter="submit"
                )
              v-text-field.mt-6(
                v-else error label="E-mail cadastrado"
                v-model="email"
                filled hide-details background-color="#F7F8FA"
                border-color=borderColorEmail
                border-bottom=solid     
                )              
              span.ml-3(
            style="color: #FF5252; font-size: 12px;"
            ) {{emailErrorMessage}}

              v-btn.mt-6(
                color="secondary darken-2"
                large
                @click="submit"
                :loading="loading" 
                block
               ) Enviar
               
                
            v-window-item
              v-row(no-gutters justify="center")
                v-col(cols="auto")
                  v-avatar(color="#F2FCFF" size="104px")
                    v-icon(color="primary darken-3" x-large) mdi-send-outline
              v-row(no-gutters justify="center")
                v-col(cols="auto")
                  h3.mt-9.analise-heading-sm.primary--text.text--darken-3 Pronto, instruções enviadas.
              v-row(no-gutters justify="center")
                v-col(cols="auto" align="center")
                  p.analise-subtitle-sm
                    | Verifique seu e-mail e siga as instruções fornecidas.
                    br
                    | O link expira em 2 horas, tá?
                  v-btn.mt-12(color="secondary darken-2" large @click="$router.push('/')" block) Voltar para página inicial
  div(:style="gradientStyle" v-if="$vuetify.breakpoint.smAndUp")
</template>

<script>
import { mapState } from 'vuex'
import Services from '../services/services'

export default {
  name: 'RecoverPassword',
  data: () => ({
    email: '',
    step: 0,
    // variavel para saber se está válido
    valid: false,
    // variavel para adicionar um texto de erro
    emailErrorMessage: "",
    borderColorEmail: false,
    loading: false,
  }),
  components: {},
  computed: {
    ...mapState('login', ['form']),
    gradientStyle() {
      return {
        backgroundImage: `url(${require('../assets/gradient.png')})`,
        backgroundRepeat: "round",
        backgroundAttachment: "fixed",
        backgroundSize: "cover"
      }
    }
  },
  methods: { 
    validEmail(email){
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    async submit(){
      this.loading = true 
      this.emailErrorMessage = ""
      // adicionar validação aqui
      // se não tiver email, o valide recebe false
      this.borderColorEmail = false
      !this.email || this.email === '' ? this.valid = false : this.valid = true
      
      // criar validação regex email
      this.validEmail(this.email) ? this.valid = true : this.valid = false
      
      if(this.valid) {
        this.borderColorEmail = false
        this.emailErrorMessage = ""
        this.user = await Services().recover({'email': this.email})
        if(this.user.status === 200 && !(this.user.data.error === "User matching query does not exist.")){
          this.step++          
        } else {
          this.borderColorEmail = true
          this.emailErrorMessage = "E-mail não cadastrado"
        }
      } else {
        this.borderColorEmail = true
        this.emailErrorMessage = "E-mail inválido"
      }  
     
      this.loading = false
      
    }
  }
}
</script>

<style lang="sass" scoped>
.grid
  display: grid
  grid-auto-flow: column
  grid-auto-columns: 1fr
  height: 100%
</style>