<template lang="pug">
v-navigation-drawer.analise--drawer(app mini-variant-width="80" permanent :mini-variant.sync="mini")
  template(#prepend)
    .pa-6(@click="$store.commit('drawer/toggleMini')")
      v-hover(v-if="mini" v-slot="{ hover }")
        v-row(justify="center" align="center")
          v-col.d-flex.flex-nowrap.px-0(cols="auto")
            div(style="height: 32px")
              v-img(v-show="!hover" eager src="../assets/logo-color-mini.png" contain width="25px")
              v-btn.px-0.rounded(v-show="hover" icon :ripple="false")
                Icon(width="18px" height="18px" icon="ic:baseline-menu")
      v-row.flex-nowrap(v-else justify="space-between" align="center")
        v-col(cols="auto")
          v-img(eager src="../assets/logo-color-horizontal.svg" contain max-width="126px")
        v-col(cols="auto")
          v-btn.rounded(icon :ripple="false")
            Icon(width="18px" height="18px" icon="ic:baseline-menu-open")
    v-divider
    div(:class="`pa-${mini ? 2 : 4}`")
      v-card.d-flex.align-items-center.overflow-hidden.pa-3(:width="`${mini ? 64 : 224}px`" :height="`59px`" elevation=0 style="border: 1px solid #EDEEF2" rounded="lg")
        v-row.flex-nowrap(justify="center" align="center")
          v-col(:cols="mini ? 12 : 3")
            v-avatar(size="32px" font-size="12px" color="#005A73" class="white--text")
              | {{ infoProfile.name.substring(0,2).toUpperCase() }}
          v-col(cols=7 v-if="!mini")
            v-card-title.text-truncate.pa-0.pb-4.analise-heading-xs(style="font-size: 14px; color: #005A73; font-weight: 500; line-height: 17px") {{infoProfile.name | sliceText}}
            v-card-subtitle.text-truncate.pa-0.analise-subtitle-xs(style="font-size: 12px; color: #9A99A2; font-weight: 500; line-height: 17px") {{infoProfile.group}}
            a(href="/logout" style="text-decoration: none;") Sair
          v-col(cols=2 v-if="!mini")
            Icon(icon="ic:baseline-keyboard-arrow-down" color="#9A99A2" widht="18px").px-2
        
    v-list(dense nav)
      div(v-for="item in items" :key="item.id")
        v-divider.my-2(v-if="item.divider")
        v-subheader.my-2.mt-3.analise-subtitle-md(style="font-size: 12px !important; text-transform: uppercase; line-height: 14px; letter-spacing: 0.1em;" v-else-if="item.subheader && !mini") {{ item.title }}
        v-list-item.px-3.py-1.my-2(v-else-if="!item.subheader && !item.divider" link :to="item.target" color="#005A73")
          v-list-item-icon(:class="mini ? '' : 'mr-3'")
            Icon(style="width: 100%; height: 100%" :icon="item.icon")
          v-list-item-content(v-if="!item.mini")
            v-list-item-title.text-truncate(style="fontSize: 12px; font-weight: 600") {{ item.title }}
</template>

<script>
import { mapState } from 'vuex'
import Services from '../services/services'
import axios from 'axios'

export default {
  computed: {
    ...mapState('drawer', ['items', 'mini']),
},
  data() {
    return{
      infoProfile: {}
    }
  },
  filters: {
      sliceText: function(value) {
        return value.slice(0, 12) + `${value.length >= 12 ? '...' : ''}`;
      }
    },
  mounted() {
    axios.get('/api/infoProfile')
      .then(response => {
        this.infoProfile = response.data
    })
},
  methods: {
    async profile() {
      try {
        this.infoProfile = await Services().profile()
      } catch(e) {
        if(e.status === 401) {
          this.refresh = await Services().refresh()
        }
        return false
      }
    }
  },
  created() {
    this.profile()
  }
}
</script>

<style lang="sass">
.analise--drawer
  display: flex
  flex-direction: column
  background-color: #F7F8FA !important
  overflow: auto

.v-divider
  border-color: #EDEEF2 !important
</style>