export const state = {
  items: [
    {
      id: 0,
      title: 'Página inicial',
      icon: 'ic:outline-home',
      target: '/'
    },
    {
      id: 1,
      divider: true
    },
    {
      id: 2,
      subheader: true,
      title: 'Redações'
    },
    {
      id: 3,
      title: 'Atividades',
      icon: 'ic:outline-assignment',
      target: '/activities/',
    },
    // {
    //   id: 4,
    //   title: 'Temas',
    //   icon: 'ic:outline-sticky-note-2',
    //   target: '/themesstudent',
    // },
    // {
    //   id: 5,
    //   title: 'Histórico',
    //   icon: 'ic:outline-pending-actions',
    //   target: '/pendent',
    // },
    {
      id: 6,
      divider: true
    },/*
    {
      id: 7,
      subheader: true,
      title: 'Gerenciar'
    },
    {
      id: 8,
      title: 'Turmas',
      icon: 'ic:outline-groups',
      target: '/manage/classes',
    },
    {
      id: 9,
      title: 'Alunos',
      icon: 'ic:outline-school',
      target: '/manage/students',
    },
    {
      id: 10,
      title: 'Usuários',
      icon: 'ic:round-people-outline',
      target: '/manage/users',
    },
    {
      id: 11,
      title: 'Escolas',
      icon: 'ic:baseline-domain',
      target: '/manage/schools',
    },*/
  ],
  mini: false
}

export const getters = {}
export const actions = {}
export const mutations = {
  toggleMini(state) {
    state.mini = !state.mini
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}