export const state = {
  form: {
    user: "",
    password: ""
  }
}

export const getters = {}
export const actions = {}
export const mutations = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}