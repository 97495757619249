<template lang="pug">
div
  //- Header.hidden-md-and-up(:title="activity.theme || ''" backBtn)
  Header(:title="activity.theme || ''" backBtn @clickSecondaryBtn="showTexts = true" @clickPrimaryBtn="dialog = true" primaryBtn primaryBtnText="Submeter para correção" primaryBtnIcon secondaryBtn secondaryBtnText="Textos motivadores" secondaryBtnIcon="ic:outline-visibility")
  v-divider
  v-row(justify="center" no-gutters style="background-color: #F7F8FA; height: 88.8vh")
    v-col(cols=12)
      v-container.mt-10
        v-row(justify="center")
          v-col
            v-card.pa-10(elevation=0)
              v-textarea(v-model="answer" auto-grow :label="areaLabel" hide-details)
  //- v-divider
  //- v-bottom-navigation.hidden-md-and-up(color="#005A73" active-class="bottom-nav-active" height="120px" fixed style="margin-top:66px")
  //-   v-btn(@clickSecondaryBtn="showTexts = true" secondaryBtn secondaryBtnText="Textos motivadores" secondaryBtnIcon="ic:outline-visibility")
  //-     span.secondaryBtnText="Textos motivadores"
  //-   v-btn(@clickPrimaryBtn="dialog = true" primaryBtn primaryBtnIcon)
  //-     span.primaryBtnText="Submeter para correção"

    

  v-navigation-drawer.no-shadow(
    v-model="showTexts"
    app
    right
    overlay-opacity="0.5"
    temporary
    width=610
  )
    .analise-heading-md.mt-1.pa-8
      span(v-if="!loading") {{ activity.theme }}
      v-skeleton-loader(v-if="loading" type="chip")
    v-chip.ml-6(label small color="#F2FCFF")
      .analise-bold(style="color: #005A73") {{ activity.category }}
    div.pt-6.pa-8
      v-skeleton-loader(v-if="loading" type="text@31, paragraph")
      div(v-if="!loading" v-html="activity.body")
      br
      h3(v-if="!loading") TEXTO I
      p(v-if="!loading") {{activity.text_1}}
      h3(v-if="!loading") TEXTO II
      p(v-if="!loading") {{activity.text_2}}
      h3(v-if="!loading") TEXTO III
      p(v-if="!loading") {{activity.text_3}}
  v-dialog(v-model="dialog" width="554px" :persistent="sending")
    v-card.pa-7
      v-row(justify="space-between")
        v-col(cols="auto")
          .py-0.my-0.analise-heading-md.secondary--text.text--darken-3(style="font-size: 24px") Submeter para correção
        //- v-col(cols="auto")
        //-   v-btn(icon @click.stop="dialog = false")
        //-     Icon(width="36px" icon="ic:outline-close" color="black") Submeter para correção
      v-divider.my-4
      p.pt-4.analise-bold(style="color: #005A73") Atenção
      p.pb-8 Ao enviar sua redação, ela não poderá mais ser modificada. 
        br
        | Tem certeza que deseja enviar?
      v-row(justify="space-between")
        v-col(cols="auto")
          v-btn.analise-bold(text @click.stop="dialog = false" :disabled="submitting") Voltar
        v-col(cols="auto")
          v-btn.white--text(color="#2D848E" elevation=0 :loading="loading" @click.stop="send" :disabled="submitting") Enviar para correção          
          template(v-if="loadingData")
            v-col
              swiper(:options="swiperOptions" class="mySwiper")
                swiper-slide(v-for="i in [1]" :key="i")
                  v-skeleton-loader(type="list-item-two-line, table-cell" height="180")
</template>

<script>
import Services from '../../services/services'

export default {
  data() {
    return {
      loading: false,
      submitting: false,
      showTexts: false,
      answer: null,
      dialog: false,
      sending: false,
      activies_create: null,
      infoProfile: null,
      loadingData: false,
      swiperOptions: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
    }
  },
  computed: {
    areaLabel() {
      return this.answer ? '' : 'Escreva aqui sua redação...'
    },
    activity() {
      const id = this.$route.params.id
      const activity = this.$store.getters['activities/getActivityById'](id)
      return activity || { theme: {} }
    }
  },
  methods: {
    async profile() {
      this.infoProfile = await Services().profile()
    },
    async send() {
      this.loading = true
      this.submitting = true;
      const id = this.$route.params.id
      this.activies_create = await Services().activies_submite_create({'text': this.answer, 'activie_id': Number(id)}, this.infoProfile.profileId)
      
      
      this.$store.dispatch('activities/submit', { id, text: this.answer })
      this.$router.push(`/activities/`)
      this.loading = false
      this.loadingData = true
      this.submitting = false;
    }
  },
  async created() {
    this.loading = true
    this.profile()
    await this.$store.dispatch('activities/list')
    this.loading = false
  }
}
</script>

<style scoped>
.no-shadow {
  box-shadow: none !important
}
</style>