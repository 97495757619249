import http from '../http'
import openhttp from '../http/openhttp'

export default function Services() {  

    const login = async (queryParams) => {
        const api = await http.post('login/', queryParams)
        window.localStorage.setItem('accessToken', api.data.access)
        window.localStorage.setItem('fixedToken', api.data.fixed_token)
        window.localStorage.setItem('refreshToken', api.data.refresh)
        if(api.status === 200){
            const token = window.localStorage.getItem('accessToken')
             const refreshToken = window.localStorage.getItem('refreshToken')
             api.headers.Authorization = `Bearer ${token}`
             api.headers['refresh'] = refreshToken
            window.location.href = '/'
        }else if(api.status === 400 || api.status === 401){
            alert("E-mail ou senha incorreta. Tente novamente.")
        }
        return api
    }
    const logout = async (queryParams) => {        
        const api = await http.post('logout/', queryParams)
        window.localStorage.removeItem('accessToken')
        window.localStorage.removeItem('fixedToken')
        window.localStorage.removeItem('refreshToken')
        if(api.status === 204){
            window.location.href = '/login'
        }      
        return api
    }

    const profile = async () => {
        try {
            const profile = await http.get('profile/')        
            return profile.data            
            
        } catch (error) {
            // window.location.href = '/login'
            return false

        }
        // if(profile.status !== 400 && profile.status !== 401 && profile !== undefined){               
            
        // }
        // else{
        //     window.location.href = '/login'
        //     return false
        // }
    }

    const activies_list = async () => {
        try {
            const api = await http.get('activies/list/')
            while(api.status === 401){
                return api.data
            }
            return api.data
        } catch (error) {
            const api = await http.get('activies/list/')
            return api
        }
    }
    const activie_id = async (idActivity) => {
        try {
            const api = await http.get('activies/submite/' +idActivity+ '/')
            return api.data[0]
        } catch(error){
            return false
        }
    }

    const activies_submite_list = async () => {
        try {            
            const api = await http.get('activies/submite/list/')
            return api.data
        } catch (error) {
            return false
        }
    }

    const activies_submite_create = async (queryParams) => {
        try {
            const api = await http.post('activies/submite/list/', queryParams)
            return api.data
        } catch (error) {
            return false
        }
    }
    
    const classes_list = async () => {
        try {
            const api = await http.get('classes/list/')
            return api.data
        } catch (error) {
            return false
        }
    }

    const themes_list = async () => {
        try {
            const api = await http.get('themes/list/')
            console.log(api.data)
            return api.data
        } catch (error) {
            return false
        }
    }
    const recover = async (queryParams) => {        
        try {
            const api = await openhttp.post('request-reset-email/', queryParams)
            
            return api
        } catch (error) {
            return false
        }
    }
    
    const token_reset = async (uidb64, token) => {
        try {
            const api = await openhttp.get(
                'password-reset/'+uidb64+'/'+token+'/',
                
            )
            if(api.status === 200){           
                window.localStorage.setItem('token', api.data.token)
                window.localStorage.setItem('uidb64', api.data.uidb64)
            }
            return api

        } catch (error) {
            return false
        }
    }
    
    const new_password = async (queryParams) => {
        try {
            const api = await openhttp.patch(
                'password-reset-complete/',
                queryParams
            )
            // if(api.status === 200){
            //     window.localStorage.removeItem('token')
            //     window.localStorage.removeItem('uidb64')
            // }
            return api
        } catch (error) {
            return false
        }
    }    
    
    return { 
        recover,
        login,
        logout,       
        profile,
        activies_list,
        activie_id,
        activies_submite_create,
        classes_list,
        themes_list, 
        activies_submite_list,
        token_reset,
        new_password,
        

    }
}
