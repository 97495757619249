<template lang="pug">
div
  .pa-6
    v-row(no-gutters justify="space-between" align="center")
      v-col(cols="auto")
        .analise-heading-md.secondary--text.text--darken-3 Gerenciar turmas
      v-col(cols="auto")
        v-btn(text elevation=0 x-large @click.stop="openClassImporterDrawer")
          Icon.mr-2(width="24px" height="24px" icon="ic:outline-download")
          .black--text Importar turmas
        v-btn(color="secondary darken-2" elevation=0 x-large @click.stop="openClassCreatorDrawer") 
          Icon.mr-2(width="24px" height="24px" icon="ic:outline-add")
          div Criar nova turma
  v-divider
  .pa-6
    v-row.analise-filters
      v-col(cols=3 xs=6)
        v-text-field(v-model="filters.name" label="Procurar pelo nome da turma" filled hide-details="auto" background-color="#F7F8FA")
          template(v-slot:append)
            Icon(icon="ic:outline-search" width="24px" height="24px")
      v-col(cols=2 xs=6)
        v-autocomplete(:items="teachers" item-value="id" item-text="name" label="Professor" filled hide-details="auto" background-color="#F7F8FA")
    v-card.mt-6(outlined)
      v-data-table.analise-data-table(:page.sync="table.page" :headers="table.headers" outlined :items-per-page="table.itemsPerPage" disable-sort hide-default-footer :items="classesItems" :loading="table.loading")
        template(v-slot:item.actions="{ item }")
          v-btn.rounded(icon)
            Icon(icon="ic:outline-info" width="24px" height="24px")
          v-btn.rounded(icon @click.stop="openEditDialog(item.id)")
            Icon(icon="ic:outline-edit" width="24px" height="24px")
          v-btn.rounded(icon color="error" @click.stop="openDeleteDialog(item.id)")
            Icon(icon="ic:outline-delete" width="24px" height="24px")
    v-row(justify="end")
      v-col(cols="auto")
        v-pagination.pt-4(v-model="table.page" :length="paginationLength" color="#005A73" :disabled="table.loading")
    ClassCreatorDrawer(v-model="classCreatorDrawer" @openImporter="openClassImporterDrawer")
    ClassEditorDrawer(v-if="currentClass" v-model="classEditorDrawer" :classe="currentClass")
    ClassImporterDrawer(v-model="classImporterDrawer")
  v-dialog(v-model="deleteClass.dialog" width="554px" :persistent="deleteClass.loading")
    v-card.pa-7
      v-row(justify="space-between")
        v-col(cols="auto")
          .py-0.my-0.analise-heading-md.secondary--text.text--darken-3(style="font-size: 24px") Excluir turma
        v-col(cols="auto")
          v-btn(icon @click.stop="deleteClass.dialog = false")
            Icon(width="36px" icon="ic:outline-close" color="black")
      v-divider.my-4
      p.pt-4.analise-bold(style="color: #FF5252") Atenção
      p.pb-8 Os alunos dessa turma permanecerão cadastrados, porém não estarão relacionados a esta turma. Gerencie-os na aba "Alunos".
      v-row(justify="space-between")
        v-col(cols="auto")
          v-btn.analise-bold(text @click.stop="deleteClass.dialog = false") Voltar
        v-col(cols="auto")
          v-btn.white--text(color="error" elevation=0 :loading="deleteClass.loading" @click.stop="deleteClassDialog") Excluir turma
</template>

<script>
import { mapState } from 'vuex'
import ClassCreatorDrawer from '@/components/classes/ClassCreatorDrawer'
import ClassEditorDrawer from '@/components/classes/ClassEditorDrawer'
import ClassImporterDrawer from '@/components/classes/ClassImporterDrawer'

export default {
  name: 'Classes',
  data: () => ({
    checkbox: false,
    textField: '',
    filters: {
      name: null,
      teacherId: null
    },

    classCreatorDrawer: false,
    classImporterDrawer: false,
    classEditorDrawer: false,
    
    currentClass: null,

    deleteClass: {
      loading: false,
      dialog: false,
      id: null
    },
    table: {
      loading: false,
      page: 1,
      itemsPerPage: 9,
      headers: [
        {
          text: 'Turma',
          value: 'name'
        },
        {
          text: 'Professor',
          value: 'teacherName'
        },
        {
          text: 'Qtde. de alunos',
          value: 'students'
        },
        {
          align: 'end',
          width: '25%',
          text: 'Ações',
          value: 'actions'
        }
      ]
    }
  }),
  methods: {
    openDeleteDialog(id) {
      this.deleteClass.id = id
      this.deleteClass.dialog = true
    },
    openEditDialog(id) {
      this.currentClass = this.classes.list.find(x => x.id === id)
      this.classEditorDrawer = true
    },
    async deleteClassDialog() {
      this.deleteClass.loading = true
      await this.$store.dispatch('classes/delete', this.deleteClass.id)
      this.deleteClass.loading = false
      this.deleteClass.dialog = false
      this.$toast.success("Turma excluída com sucesso.")
    } ,
    openClassCreatorDrawer() {
      this.classCreatorDrawer = !this.classCreatorDrawer
    },
    openClassImporterDrawer() {
      this.classImporterDrawer = !this.classImporterDrawer
    },
  },
  computed: {
    ...mapState(['users', ['list']]),
    ...mapState(['classes', ['list']]),
    teachers() {
      return this.users.list.filter(x => x.type.id === 2)
    },
    classesItems() {
      if (!this.teacherId) {
        return this.classes.list
      }

      return this.classes.list.filter(x => x.teacherId === this.teacherId)
    },
    paginationLength() {
      return Math.ceil(this.classes?.list?.length / this.table.itemsPerPage)
    }
  },
  components: {
    ClassCreatorDrawer,
    ClassEditorDrawer,
    ClassImporterDrawer,
  },
  async created() {
    this.table.loading = true
    await this.$store.dispatch("users/list")
    await this.$store.dispatch("classes/list")
    this.table.loading = false
  }
}
</script>
