<template lang="pug">
  div
    Header(title="Atividades" prependIcon="ic:outline-assignment" primaryBtn primaryBtnText="Adicionar Atividade")
    v-divider
    v-tabs.px-8(v-model="tab" color="#005A73")
      v-tab.text-none
        .analise-bold Pendentes
      v-tab.text-none
        .analise-bold Concluídas
    v-divider
    .pa-8
      v-card(outlined)
        v-data-table(:headers="table.headers" :items="activities.list" hide-default-footer)
          template(v-slot:item.actions="{item}")
            v-btn.rounded(icon @click.stop="$router.push({ name: 'ActivityManager', params: { id: item.id }})")
              Icon(icon="ic:outline-info" width="24px" height="24px")
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      tab: 0,
      table: {
        headers: [
          {
            text: 'Tema',
            value: 'theme'
          },
          {
            text: 'Data de entrega',
            value: 'date'
          },
          {
            text: 'Submetidas',
            value: 'submissions'
          },
          {
            text: 'Nota',
            value: 'grade'
          },
          {
            text: 'Ações',
            value: 'actions'
          }
        ]
      }
    }
  },
  computed: {
    ...mapState(['activities', ['list']])
  },
  async created() {
    await this.$store.dispatch('activities/list')
  }
}
</script>