<template lang="pug">
  v-container.fluid.pa-2.ma-2
    v-card.pa-5(outlined elevation=0 width="309px" height="130px" rounded="lg" style="cursor: pointer" @click.stop="$router.push('/activity/'+activity.id)")
      .analise-subtitle-sm(class="font-weight-bold color=#1F1F27") {{ theme.name | sliceText }}
      v-row(justify="space-between" align="center")
        v-col(cols="auto")
          v-chip.mt-9(label small color="#F2FCFF")
            .analise-bold(style="color: #005A73") {{ theme.category || 'Sem Categoria' }}
        v-col(cols="auto" v-if="theme.done" width="16px" style="color: #005A73")
          Icon(icon="ic:outline-check")
</template>


<script>
export default {
  props: ['theme'],
  filters: {
    sliceText: function(value) {
    return value.slice(0, 40) + `${value.length >= 40 ? '...' : ''}`;
    }
  }
}
</script>