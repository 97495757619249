<template lang="pug">
  v-container(fluid)
    Header(title="Atividades" prependIcon="ic:outline-sticky-note-2")
    v-divider
    .pa-6
      v-row(no-gutters justify="space-between" align="end" style="color:#403F4C")
        v-col(cols="12" md="auto")
          .analise-subtitle-sm(class="font-weight-bold") Atividades pendentes
        v-col(cols="12" md="auto")
          v-btn(color="secondary darken-4" text outlined @click="$router.push('/pendingactivies')")
            .analise-bold Ver todas
      v-row
        template(v-if="loadingData")
          v-col(cols="10")
            swiper.swiper(:options="swiperOptions")
              swiper-slide(v-for="i of [1,2,3]" :key="i" )
                v-card.pa-5(outlined elevation=0 max-width="309" max-height="164" rounded="lg")
                  v-skeleton-loader(type="list-item-two-line, table-cell" max-height="164")
        template(v-else)
          v-col(cols="12")
            swiper.swiper(:options="swiperOptions")
              swiper-slide(v-for="(item) of pendentList" :key="item.theme")
                ActivityCard.my-5.mt-0(:activity="item")
      .analise-subtitle-sm.analise-bold.pt-3(class="font-weight-bold") Últimas submissões
      v-card.my-4.hidden-xs-only(outlined elevation=0)
        v-data-table.analise-data-table(hide-default-footer :loading="loadingData" :headers="table.headers" :items="doneList")
          template(v-slot:item.status="{ item }")
            v-chip(label :color="item.status === 'revised' ? '#F4FDF4' : item.status === 'late' ? '#FEF2F2' : '#F2FCFF'")
              .analise-bold(:style="{color: item.status === 'revised' ? '#196416' : item.status === 'late' ? '#670000' : '#005A73'}") {{ item.tag }}
          template(v-slot:item.actions="{ item }")
            v-btn(v-if="item.status != 'late'" icon @click.stop="$router.push(`/activity/${item.id}/feedback`)")
              Icon(icon="ic:outline-info" width="24px")
      div.hidden-sm-and-up(v-for="(item, index) of doneList" :key="index" @click.stop="$router.push(`/activity/${item.id}/feedback`)")
        ActivityComplete.my-5.mt-0(:items="item")
</template>

 
<script>
import ActivityCard from '@/components/ActivityCard'
import ActivityComplete from '@/components/ActivityComplete'
import { mapMutations, mapState } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import Services from '../../src/services/services'

export default {
  components: { 
    ActivityCard,
    ActivityComplete,
    Swiper,
    SwiperSlide
  },
  computed: {
    ...mapState('activities', ['list']),
    pendentList() {
      return this.list.filter(x => x.status === 'pending')
    },
    doneList() {
      return this.list.filter(x => x.status !== 'pending')
    },
    test(){
      return this.$store.state.activities.list
    }
  },
  async created() {
    this.loadingData = true
    this.infoProfile = await Services().profile()
    await Services().activies_submite_list(this.infoProfile.profileId).then((res) => {this.idActivity = res})
    await Services().activies_list(this.infoProfile.profileId).then((res) => {
      this.$store.commit('activities/setList', res)
    })
    //await this.$store.dispatch('list' + this.infoProfile.profileId + '/')
    this.loadingData = false
  },
  methods: {
    ...mapMutations(['activities/setList']),
    goToActivity(id) {
      this.$router.push({ name: 'ActivityFeedback', params: { id }})
    }
  },
  data() {
    return {
      idActivity: '',
      idSubmit: '',
      activie: null,
      infoProfile: null,
      swiperOptions: {
        slidesPerView: this.$vuetify.breakpoint.mdAndUp ? 4 : 'auto',
        spaceBetween: 5
      },
      loadingData: false,
      table: {
        headers: [
          {
            text: 'Tema',
            value: 'theme'
          },
          {
            text: 'Data de entrega',
            value: 'date'
          },
          {
            text: 'Status',
            value: 'status'
          },
          {
            text: 'Nota',
            value: 'grade'
          },
          {
            text: 'Ações',
            value: 'actions'
          }
        ]
      }
    }
   },
   watch:{
      doneList(){
        console.log('ahisduiaojl')
        console.log(this.doneList)
        console.log('ahisduiaojl')

      }
   },
  async mounted () {
     await Services().activies_list(this.infoProfile.profileId).then((res) => {
      this.$store.commit('activities/setList', res)
    })
    },
}
</script>