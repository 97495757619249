<template lang="pug">
  v-container.fluid.pa-2.ma-2
    v-card.pa-5(outlined elevation=0 max-width="309" max-height="164" rounded="lg")
      .analise-subtitle-sm.mb-5 {{ activity.theme | sliceText }}
      v-row(justify="space-between" align="center")
        v-col(cols="auto")
          Icon.mr-2(icon="ic:outline-calendar-today" width="12px" color="#9A99A2")
          span {{ activity.date }}
        v-col(cols="auto")
          v-chip(label small color="#F2FCFF")
            .analise-bold(style="color: #005A73") {{ activity.category }}
      div.pt-5(text style="cursor: pointer" @click.stop="$router.push('/activity/'+activity.id)")
        v-row(no-gutters align="center")
          v-col(cols="auto")
            span.analise-bold(style="color: #005A73") Ver atividade
          v-col.d-flex.flex-row.align-center(cols="auto")
            Icon.ml-2(icon="ic:outline-arrow-forward" width="16px" height="16px" color="#005A73")
</template>

<script>
export default {
  props: ['activity'],
  filters: {
      sliceText: function(value) {
        return value.slice(0, 40) + `${value.length >= 40 ? '...' : ''}`;
      }
    }
}
</script>