import { getField, updateField } from 'vuex-map-fields'
import Services from '../../../services/services'

export const state = {
  list: [],
  neverListed: true
}

export const getters = {
  getField
}
export const actions = {
  // eslint-disable-next-line
  async list({ commit, dispatch, state, rootState }, { force = false, ...params } = {}) {
    if (state.neverListed || force) {
      await new Promise((resolve) => setTimeout(resolve, 1500))
      commit('setList', await Services().classes_list())
      commit('setNeverListed', false)
    }
  },
  // eslint-disable-next-line
  async create({ commit, dispatch, state, rootState }, { ...params } = {}) {
    await new Promise((resolve) => setTimeout(resolve, 500))
    commit('addToList', params)
  },
  // eslint-disable-next-line
  async delete({ commit, dispatch, state, rootState }, id) {
    await new Promise((resolve) => setTimeout(resolve, 500))
    commit('setList', state.list.filter(x => x.id !== id))
  },
  // eslint-disable-next-line
  async update({ commit, dispatch, state, rootState }, { id, ...data }) {
    await new Promise((resolve) => setTimeout(resolve, 200))
    const newList = state.list
    newList[newList.indexOf(x => x.id === id)] = {
      ...newList[newList.indexOf(x => x.id === id)],
      ...data
    }
    commit('setList', newList)
  }
}
export const mutations = {
  updateField,
  setList(state, data) {
    state.list = data
  },
  addToList(state, data) {
    state.list.unshift(data)
  },
  setNeverListed(state, data) {
    state.neverListed = data
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}