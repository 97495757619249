import axios from 'axios'

const http = axios.create({
    baseURL: process.env.VUE_APP_API_BASE,
    timeout: 15000,
})

const requestHandler = (request) => {
    const token = window.localStorage.getItem('accessToken')
    const refreshToken = window.localStorage.getItem('refreshToken')
    request.headers.Authorization = `Bearer ${token}`
    request.headers['refresh'] = refreshToken

    return request
}

const responseHandler = (response) => {
    return response
}

const errorHandler = async (error) => {
    const originalConfig = error.config
    if(error.response.data.detail === "Token is invalid or expired" || error.response.data.detail === "Given token not valid for any token type"){
        originalConfig._retry = false
        window.location.href = '/login'
    }
    else if (error.response.status === 401 && ! originalConfig._retry && error.response.code !== "token_not_valid"){
        originalConfig._retry = true
        try {
            const refresh = await http.post(
                'login/refresh/',
                {'refresh': window.localStorage.getItem('refreshToken')
            })
            const access = refresh.data.access
            window.localStorage.setItem(
                'accessToken',
                access
            )                
              
            if(refresh.status === 200){
                window.location.reload()
            }
            return http(
                originalConfig
            )
        }
        catch (e){
            originalConfig._retry = false      
            return false
        }
    }     
    return Promise.reject(error)
}

 http.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
)

http.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
)

export default http
