import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const state = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules,
  plugins: [createPersistedState()],
})

export default state