import { getField, updateField } from 'vuex-map-fields'

export const state = {
  meta: {}
}

export const getters = {
  getField
}
export const actions = {
  // eslint-disable-next-line
  updateMeta({ commit, dispatch, state, rootState }, meta) {
    meta.public = typeof meta.public === 'boolean' ? meta.public : false
    meta.showDrawer = typeof meta.showDrawer === 'boolean' ? meta.showDrawer : true
    commit('setMeta', meta)
  }
}
export const mutations = {
  updateField,
  setMeta(state, data) {
    state.meta = data
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}