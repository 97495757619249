<template lang="pug">
  v-container.fluid
    Header(title="Atividades" prependIcon="ic:outline-sticky-note-2")
    v-divider
    v-card-text.pa-6(outlined)
      v-row.analise-filters
        v-col(cols="12" md="5")
          v-text-field(v-model="filters.name" label="Pesquisar por título ou tema" filled hide-details="auto" background-color="#F7F8FA")
        v-col(cols="auto" style="position: absolut; right: 0;")
          v-row(align="center")
            v-col.my-4.hidden-sm-and-down(cols="auto")
              .analise-bold(style="font-size: 12px") Ordenar
            v-col(cols="auto")
              v-chip-group(align="right" v-model="filters.status" mandatory active-class="chip-active")
                v-chip(color="#F2FCFF" @click="hasFilter = true")
                  .analise-bold(style="color: #005A73") Ordem alfabética
                v-chip(color="#F2FCFF"  @click="hasFilter = false")
                  .analise-bold(style="color: #005A73") Adicionados recentemente
      v-row
        template(v-if="loadingData")
          v-col(cols="12")
            swiper.swiper(:options="swiperOptions" style="display: flex; flex-direction: row; flex-wrap: wrap; gap: 50px;")
              swiper-slide(v-for="i of [1,2,3]" :key="i" )
                v-card.pa-5(outlined elevation=0 width="309" height="164" rounded="lg")
                  v-skeleton-loader(type="list-item-two-line, table-cell" max-height="164")     
        template(v-else)
          v-col(cols="12")
            swiper.swiper(:options="swiperOptions" style="display: flex; flex-direction: row; flex-wrap: wrap;")
              swiper-slide(v-for="(item) of this.pendList" :key="item.theme")
                ActivityCard.my-5.mt-0(:activity="item")         
</template>

<script>
import { mapState } from 'vuex'
import ActivityCard from '../../components/ActivityCard.vue'
import Services from '../../services/services'

export default {
  components: { 
    ActivityCard
  },
  data() {
    return {
      loadingData: true,
      filters: {
        list: [],
        name: '',
        status: null
      },
      hasFilter: true,
      list: [],
      pendList: []
    }
  },
  computed: {
    ...mapState('themes', ['list']),
    // pendentList() {
    //   return this.list.filter(x => x.status === 'pending')
    // },
    filteredResults() {
      const filter = this.filters.status === 1 ? true : this.filters.status === 2 ? false : null
      if (filter === '') {
        // console.log(this.list)
        return this.list
      } else {
        return this.list.filter((list) => list.name.toLowerCase().includes(this.filters.name.toLowerCase())
      )
      }
    }
  },
  watch: {
    filteredResults(){
      // console.log(this.filteredResults)
    },
    hasFilter() {
      if(this.hasFilter){
        this.pendList.sort((a, b) => {
            let fa = a.theme.toLowerCase(),
                fb = b.theme.toLowerCase();

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        })
      } else {
        this.pendList.sort((a, b) => {
          let da = new Date(a.createdAt),
              db = new Date(b.createdAt);
          return db - da;  
        })
      }
    }
  },
  methods: {
    // ...mapActions('theme', ['list'])
  },
  mounted(){
    // chamar a API ou banco de dados para buscar temas e atualizar o estado
    // this.themes = [ ... ];
  },
  async created() {
    this.loadingData = true
    await Services().activies_list().then((res) => { 
      const teste = res
      this.list = teste; 
      this.pendList = res 
      this.pendList.sort((a, b) => {
            let fa = a.theme.toLowerCase(),
                fb = b.theme.toLowerCase();

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        })
    })
    this.loadingData = false
  }
}
</script>

<style lang="sass">
.chip-active
  background-color: #005A73 !important
  .v-chip__content
    div
      color: #FFFFFF !important
</style>