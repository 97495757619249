<template lang="pug">
v-app
  //- v-app-bar(color="secondary" app)
  //-   v-img.mx-2(src="@/assets/logo-mono-horizontal.svg" contain max-height=40 max-width=150)
  //-   v-spacer
  //-   v-btn.white--text(to="/" text) Componentes
  v-main
    Drawer(v-if="$vuetify.breakpoint.mdAndUp && meta.showDrawer")
    router-view
    TabBar(v-if="!$vuetify.breakpoint.mdAndUp && meta.showDrawer")
</template>

<script>
import Drawer from './components/Drawer'
import TabBar from '@/components/TabBar'
import { mapState } from 'vuex'

export default {
  components: {
    Drawer,
    TabBar
  },
  computed: {
    ...mapState('route', ['meta'])
  }
}
</script>

<style lang="sass">
@import https://fonts.googleapis.com/css2?family=Rubik&display=swap

.v-tooltip__content
  background: #1F1F27 !important

.v-menu__content
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 5%), 0px 8px 10px 1px rgb(0 0 0 / 5%), 0px 3px 14px 2px rgb(0 0 0 / 5%) !important

.v-text-field > .v-input__control > .v-input__slot:before
  border-color: rgba(0,0,0,0) !important
  margin-bottom: 0

.theme--light.v-label
  color: rgba(0, 0, 0, 0.8) !important

.v-btn
  text-transform: none !important
  letter-spacing: normal

.v-data-table-header
  background-color: #F7F8FA

.v-data-table-header > tr > th > span
  font-weight: bold
  font-size: 14px
  color: black !important

.analise
  &-bold
    font-weight: bold
  &-text
    font-family: 'Rubik'
    font-style: normal
    &-bold
      font-weight: 700
  &-heading
    &-xl
      font-family: 'Rubik'
      font-style: normal
      font-weight: bold
      font-size: 56px
      color: #000
    &-lg
      font-family: 'Rubik'
      font-style: normal
      font-weight: bold
      font-size: 40px
      color: #000
    &-md
      font-family: 'Rubik'
      font-style: normal
      font-weight: bold
      font-size: 32px
      color: #000
    &-sm
      font-family: 'Rubik'
      font-style: normal
      font-weight: bold
      font-size: 24px
      color: #000
    &-xs
      font-family: 'Rubik'
      font-style: normal
      font-weight: bold
      font-size: 20px
      color: #000
  &-subtitle
    &-lg
      opacity: 0.64
      font-family: 'Rubik'
      font-style: normal
      font-weight: bold
      font-size: 32px
      color: #000
    &-md
      opacity: 0.64
      font-family: 'Rubik'
      font-style: normal
      font-weight: bold
      font-size: 20px
      color: #000
    &-sm
      opacity: 0.64
      font-family: 'Rubik'
      font-style: normal
      font-weight: 400
      font-size: 16px
      color: #000
  &-primary-400-text
      color: #005A73
  &-quill-editor
    border-radius: 6px
    .ql
      &-toolbar.ql-snow
        border-width: thin
        border-radius: 6px 6px 0 0
      &-container.ql-snow
        background-color: #F7F8FA
        border-width: thin
        border-radius: 0 0 6px 6px
    &--reversed
      .ql
        &-toolbar.ql-snow
          border-width: thin
          border-radius: 0 0 6px 6px
        &-container.ql-snow
          background-color: #F7F8FA
          border-width: thin
          border-radius: 6px 6px 0 0
    &--hidden-toolbar
      .ql
        &-toolbar.ql-snow
          display: none
        &-container.ql-snow
          background-color: #F7F8FA
          border-radius: 6px

.ql-blank::before
  font-style: normal !important

#app 
  font-family: 'Rubik', Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: #2c3e50

*
  scrollbar-width: thin
  scrollbar-color: blue orange

*::-webkit-scrollbar
  width: 5px

*::-webkit-scrollbar-track
  background: transparent

*::-webkit-scrollbar-thumb
  background-color: var(--v-primary-darken2)
  border-radius: 20px
  border: 10px solid transparent
</style>
