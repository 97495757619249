<template lang="pug">
  v-container.fluid.pa-2.ma-2
    v-card.pa-5(outlined elevation=0 max-width="343" max-height="164" rounded="lg")
      .analise-subtitle-sm.mb-5 {{ items.theme | sliceText }}
      v-row(justify="space-between" align="center")        
        v-col(cols="auto")
          v-chip(label small color="#F2FCFF")
            .analise-bold(style="color: #005A73")  {{ items.grade }}
        v-col(cols="auto")
          Icon.mr-2(icon="ic:outline-calendar-today" width="12px" color="#9A99A2")
          span {{ items.date }}
      div(text style="cursor: pointer" @click.stop="$router.push('/activity/')")
      v-row(no-gutters align="center")
        v-col(cols="auto")
          v-chip(label :color="items.status === 'revised' ? '#F4FDF4' : '#F2FCFF'")
            .analise-bold(:style="{color: items.status === 'revised' ? '#196416' : '#005A73'}")  {{ items.tag }}
</template>

<script>
export default {
  props: ['items'],
  filters: {
      sliceText: function(value) {
        return value.slice(0, 40) + `${value.length >= 40 ? '...' : ''}`;
      }
    }
}
</script>