import activities from './activities'
import login from './login'
import drawer from './drawer'
import classes from './classes'
import users from './users'
import themes from './themes'
import route from './route'

export default {
  activities,
  login,
  drawer,
  users,
  classes,
  themes,
  route,
}