<template lang="pug">
  v-container.fluid
    Header(title="Temas" prependIcon="ic:outline-sticky-note-2")
    v-divider
    v-card-text.pa-6(outlined)
      v-row.analise-filters
        v-col(cols="12" md="8" sm="12" xs="12")
          v-text-field(v-model="filters.name" label="Pesquisar por tema" filled hide-details="auto" background-color="#F7F8FA")
        v-col(cols="12")
          v-row(align="center")
            v-col(cols="auto")
              .analise-bold.hidden-xs-only(style="font-size: 12px") Filtrar
            v-col(cols="auto")
              v-chip-group(v-model="filters.status" mandatory active-class="chip-active")
                v-chip(color="#F2FCFF")
                  .analise-bold(style="color: #005A73") Todos
                v-chip(color="#F2FCFF")
                  .analise-bold(style="color: #005A73") Já submetidos
                v-chip(color="#F2FCFF")
                  .analise-bold(style="color: #005A73") Nenhuma submissão
        //- v-col(cols="auto" style="position: absolut; right: 0;")
        //-   v-row(align="center")
        //-     v-col(cols="auto")
        //-       .analise-bold(style="font-size: 12px") Ordenar
        //-     v-col(cols="auto")
        //-       v-chip-group(align="right" v-model="filters.status" mandatory active-class="chip-active")
        //-         v-chip(color="#F2FCFF")
        //-           .analise-bold(style="color: #005A73") Ordem alfabética
        //-         v-chip(color="#F2FCFF")
        //-           .analise-bold(style="color: #005A73") Adicionados recentemente
      v-row
        template(v-if="loadingData")
          v-col(cols="auto")
            swiper.swiper(:options="swiperOptions")
              swiper-slide(v-for="i of [1,2,3]" :key="i" )
                v-card.pa-5(outlined elevation=0 width="309px" height="130px" rounded="lg")
                  v-skeleton-loader(type="list-item-two-line, table-cell" max-height="164")
        template(v-else)
          v-col(cols="auto" v-for="(theme) of filteredResults" :key="theme.id")
            ThemeCard.my-5.mt-0(:theme="theme")
            swiper.swiper(:options="swiperOptions")
              swiper-slide(v-for="(theme) of filteredResults" :key="theme.id")                
</template>

<script>
import { mapState } from 'vuex'
import ThemeCard from '@/components/ThemeCard'
import Services from '../services/services'

export default {
  components: { ThemeCard },
  data() {
    return {
      loadingData: true,
      filters: {
        list: [],
        name: '',
        status: null
      },
      list: []
    }
  },
  computed: {
    ...mapState('themes', ['list']),
    filteredResults() {
      const filter = this.filters.status === 1 ? true : this.filters.status === 2 ? false : null
      if (filter === '') {
        // console.log(this.list)
        return this.list
      } else {
        return this.list.filter((list) => list.name.toLowerCase().includes(this.filters.name.toLowerCase())
      )
      }
    }
  },
  watch: {
    filteredResults(){
      // console.log(this.filteredResults)
    },
  },
  methods: {
    // ...mapActions('theme', ['list'])
  },
  mounted(){
    // chamar a API ou banco de dados para buscar temas e atualizar o estado
    // this.themes = [ ... ];
  },
  async created() {
    this.loadingData = true
    await Services().themes_list().then((res) => { this.list = res })
    this.loadingData = false
  }
}
</script>

<style lang="sass">
.chip-active
  background-color: #005A73 !important
  .v-chip__content
    div
      color: #FFFFFF !important
</style>