import { getField, updateField } from 'vuex-map-fields'
import Services from '../../../services/services'
export const state = {
  list: [],
  neverListed: true
}

export const getters = {
  getField,
  getActivityById: (state) => (id) => {
    return state.list.find(x => x.id == id)
  }
}
export const actions = {
  // eslint-disable-next-line
  async list({ commit, dispatch, state, rootState }, { force = false, ...params } = {}) 
  {
    if (state.neverListed || force) {
      await new Promise((resolve) => setTimeout(resolve, 1500))
      commit('setList', await Services().activies_list())
      commit('setNeverListed', false)
    }
  },
  async submit({ commit }, { id, text }) {
    commit('addResponse', {id, text, date: '25 jan 2022' })
  }
}
export const mutations = {
  updateField,
  setList(state, data) {
    state.list = data
  },
  setNeverListed(state, data) {
    state.neverListed = data
  },
  addResponse(state, data) {
    const index = state.list.findIndex(x => x.id == data.id)
    if (!state.list[index].Responses) state.list[index].Responses = []
    state.list[index].Responses.unshift({ id: state.list[index].Responses.length, text: data.text, date: data.date, status: 'pending' })
    state.list[index].status = 'submited'
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}