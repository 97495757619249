<template lang="pug">
v-navigation-drawer(
  v-model="value"
  app
  right
  overlay-opacity="0.5"
  temporary
  stateless
  width=610
)
  .pa-4.pa-md-8
    v-row(no-gutters justify="space-between" align="center")
      v-col(cols="auto")
        .analise-heading-md.secondary--text.text--darken-3 Importar temas
      v-col(cols="auto")
        v-btn(icon elevation=0 x-large @click.stop="$emit('input', false)")
          Icon(width="36px" height="36px" icon="ic:outline-close")
    v-divider.my-5
    v-window(v-model="step")
      v-window-item
        .pa-6.mb-4(style="width: 100%; background-color: #F2FCFF; border-radius: 8px;")
          span(style="color: #005A73; font-weight: bold; font-size: 16px") Planilha modelo
          .mt-3
            .analise-subtitle-xs Utilize o modelo a seguir como base para os dados das turmas a serem importadas.
            v-btn.mt-4(outlined color="primary darken-3")
              Icon(icon="ic:outline-download" width="24px")
              .analise-bold.ml-2 Baixar modelo
        .analise-subtitle-xs Anexe a planilha com os dados referentes às turmas que deseja cadastrar:
        v-file-input.pt-4(v-model="form.file" clearable prepend-icon prepend-inner-icon="$file" filled label="Selecionar arquivo" hide-details="auto" background-color="#F7F8FA")
      v-window-item
        v-row(no-gutters justify="space-between")
          v-col(cols="auto")
            .analise-subtitle-xs Processando dados
          v-col(cols="auto")
            .analise-subtitle-xs {{ progress }}%
          v-col(cols=12)
            v-progress-linear.mt-2(v-model="progress" height=8 rounded)
      v-window-item
        .pa-6.mb-4(style="width: 100%; background-color: #F2FCFF; border-radius: 8px;")
          .d-flex.flex-row.align-center(style="color: #005A73")
            Icon(icon="ic:outline-check" width="24px")
            span.analise-subtitle-xs.ml-2 Planilha processada com sucesso.
        v-file-input.pt-4(v-model="form.file" clearable disabled prepend-icon prepend-inner-icon="$file" filled label="Selecionar arquivo" hide-details="auto" background-color="#F7F8FA")
        v-card.mt-6(elevation=0 outlined)
          v-data-table(:headers="table.headers" hide-default-footer :items="form.items")
    .pb-7.px-4(style="position: absolute; bottom: 0; width: 90%")
      v-row(justify="space-between")
        v-col(cols="auto")
          v-btn(text elevation=0 large @click.stop="step--" :disabled="step === 0")
            .analise-bold(style="font-size: 14px") Cancelar
        v-col(cols="auto")
          v-btn(color="#2D848E" elevation=0 large @click.stop="next" :loading="loading")
            div(style="font-size: 14px; color: #FFF") {{ step < 2 ? "Próximo" : "Salvar" }}
</template>

<script>
export default {
  props: ['value'],
  name: 'ClassImporterDrawer',
  data() {
    return {
      loading: false,
      progress: 30,
      step: 0,
      form: {
        file: null,
        items: [
          {
            name: 'O estigma associado às doenças mentais na sociedade brasileira',
          },
          {
            name: 'Democratização do acesso ao cinema no Brasil',
          },
          {
            name: 'Manipulação do comportamento do usuário pelo controle...',
          },
          {
            name: ' Consequências da busca por padrões de beleza idealizados',
          },
        ]
      },
      table: {
        headers: [
          {
            text: 'Tema',
            value: 'name'
          }
        ]
      }
    }
  },
  methods: {
    async next() {
      if (this.step < 2) {
        this.step++
      } else {
        this.loading = true
        this.loading = false
        this.$toast.success("Temas importadas com sucesso.")
        this.$emit('input', false)
        this.step = 0
      }
    }
  },
  async created() {
  }
}
</script>