<template lang="pug">
div
  .pa-6
    v-row(no-gutters justify="space-between" align="center")
      v-col(cols="auto")
        .analise-heading-md.secondary--text.text--darken-3 Temas
      v-col(cols="auto")
        v-btn(text elevation=0 x-large @click.stop="openThemeImporterDrawer")
          Icon.mr-2(width="24px" height="24px" icon="ic:outline-download")
          .black--text Importar temas
        v-btn(color="secondary darken-2" elevation=0 x-large @click.stop="$router.push('/themes/create')") 
          Icon.mr-2(width="24px" height="24px" icon="ic:outline-add")
          div Adicionar tema
  v-divider
  .pa-6
    v-row.analise-filters
      v-col(cols=3 xs=6)
        v-text-field(v-model="filters.name" label="Procure por nome" filled hide-details="auto" background-color="#F7F8FA")
          template(v-slot:append)
            Icon(icon="ic:outline-search" width="24px" height="24px")
    v-card.mt-6(outlined)
      v-data-table.analise-data-table(:page.sync="table.page" :headers="table.headers" outlined :items-per-page="table.itemsPerPage" disable-sort hide-default-footer :items="themes.list" :loading="table.loading")
        template(v-slot:item.category="{ item }")
          v-chip(v-if="item.category" label small color="#F2FCFF")
            .analise-bold(style="color: #005A73") {{ item.category }}
          div(v-else) -
        template(v-slot:item.actions)
          v-btn(icon)
            Icon(icon="ic:outline-info" width="24px" height="24px")
          v-btn(icon)
            Icon(icon="ic:outline-edit" width="24px" height="24px")
          v-btn(icon color="error")
            Icon(icon="ic:outline-delete" width="24px" height="24px")
    v-row(justify="end")
      v-col(cols="auto")
        v-pagination.pt-4(v-model="table.page" :length="paginationLength" color="#005A73" :disabled="table.loading")
    ThemeImporterDrawer(v-model="themeImporterDrawer")
</template>

<script>
import { mapState } from 'vuex'
import ThemeImporterDrawer from '@/components/themes/ThemeImporterDrawer'

export default {
  name: 'Themes',
  data: () => ({
    checkbox: false,
    textField: '',
    filters: {
      name: null,
      teacherId: null
    },
    classCreatorDrawer: false,
    themeImporterDrawer: false,
    table: {
      loading: false,
      page: 1,
      itemsPerPage: 9,
      headers: [
        {
          text: 'Tema',
          value: 'name'
        },
        {
          text: 'Categoria',
          value: 'category'
        },
        {
          text: 'Qtde. de submissoes',
          value: 'submissions'
        },
        {
          align: 'end',
          width: '15%',
          text: 'Ações',
          value: 'actions'
        }
      ]
    }
  }),
  methods: {
    openClassCreatorDrawer() {
      this.classCreatorDrawer = !this.classCreatorDrawer
    },
    openThemeImporterDrawer() {
      this.themeImporterDrawer = !this.themeImporterDrawer
    },
  },
  computed: {
    ...mapState(['themes', ['list']]),
    paginationLength() {
      return Math.ceil(this.themes?.list?.length / this.table.itemsPerPage)
    }
  },
  components: {
    ThemeImporterDrawer,
  },
  async created() {
    this.table.loading = true
    await this.$store.dispatch("themes/list")
    this.table.loading = false
  }
}
</script>
