<template lang="pug">
div
  Header(title="Cadastrar tema" backBtn primaryBtn primaryBtnIcon primaryBtnText="Salvar alterações" @clickPrimaryBtn="save")
  v-divider
  .pa-8
    v-row
      v-col(cols=8)
        .analise-bold Descrição do tema
        v-form.py-4
          v-text-field.mb-4(label="Tema" v-model="form.title" filled hide-details="auto" background-color="#F7F8FA")
          v-text-field.mb-4(label="Categoria" v-model="form.category" filled hide-details="auto" background-color="#F7F8FA")
          quill-editor.analise-quill-editor(v-model="form.body")
      v-col(cols=4)
        .pa-6.my-4(style="width: 100%; background-color: #F2FCFF; border-radius: 8px;")
          span(style="color: #005A73; font-weight: bold; font-size: 16px") Importar temas
          .mt-3
            v-row
              v-col(cols=12)
                span Facilite a criação de novos temas através da importação em lote.
              v-col(cols=12)
                v-btn.analise-bold(outlined block color="primary darken-3" @click.stop="themeImporterDrawer = !themeImporterDrawer") Importar
  ThemeImporterDrawer(v-model="themeImporterDrawer")
</template>

<script>
import ThemeImporterDrawer from '@/components/themes/ThemeImporterDrawer'

export default {
  name: 'ThemeCreator',
  data: () => ({
    themeImporterDrawer: false,
    form: {
      title: '',
      category: '',
      body: ''
    }
  }),
  components: {
    ThemeImporterDrawer
  },
  methods: {
    save() {
      this.$toast.success("Tema cadastrado com sucesso.")
      this.$router.go(-1)
    }
  },
  computed: {
  },
  async created() {
  }
}
</script>
