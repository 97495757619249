<template lang="pug">
div
  Header(title="Atividade" backBtn)
  v-divider
  .pa-8
    v-row
      v-col(cols="12" md="8")
        .pt-2.analise-subtitle-xs(style="font-size: 12px; letter-spacing: 0.08em;") ATIVIDADE
        .analise-heading-md.mb-2
          span(v-if="!loading") {{ activity.theme }}
          v-skeleton-loader(v-if="loading" type="chip")
        v-chip(label small color="#F2FCFF")
          .analise-bold(style="color: #005A73") {{ activity.category }}
        div.pt-6
          v-skeleton-loader(v-if="loading" type="text@31, paragraph")
          div(v-if="!loading" v-html="activity.body")
          br
          h3(v-if="!loading") TEXTO I
          p(v-if="!loading") {{activity.text_1}}
          h3(v-if="!loading") TEXTO II
          p(v-if="!loading") {{activity.text_2}}
          h3(v-if="!loading") TEXTO III
          p(v-if="!loading") {{activity.text_3}}
      v-col(cols=4)
        v-card.hidden-sm-and-down(elevation=0 dense style="background-color: #F7F8FA")
          .pa-6
            v-chip(label small color="#F2FCFF")
              .analise-bold(style="color: #005A73") Pendente
            .py-5
              v-row(no-gutters justify="space-between" align="center")
                v-col(cols="auto")
                  .analise-subtitle-xs Data de entrega
                v-col(cols="auto")
                  .analise-subtitle-sm.analise-bold.black--text
                    span(v-if="!loading") {{ activity.date }}
                    v-skeleton-loader(v-if="loading" type="text" width="90px")
            v-divider
            v-menu(offset-y rounded="lg")
              template(v-slot:activator="{ on, attrs }")
                v-btn.white--text.mt-5(color="#2D848E" large block elevation=0 v-on="on" v-bind="attrs") Iniciar atividade
              v-list.pa-3
                v-list-item(@click.stop="$router.push(`/activity/${$route.params.id}/submit/write`)")
                  v-list-item-icon.mr-2
                    Icon(icon="ic:outline-create"  color="black" width="20px")
                  v-list-item-title Digitar redação
                //- v-list-item(@click.stop="$router.push(`/activity/${$route.params.id}/upload`)")
                //-   v-list-item-icon.mr-2
                //-     Icon(icon="ic:outline-upload"  color="black" width="20px")
                //-   v-list-item-title Enviar manuscrito
        div(v-if="response_id")
          v-card.my-4.hidden-sm-and-down(elevation=0 dense style="background-color: #F7F8FA" v-for="(response, index) of response_id" :key="response.id")
            .pa-6
              .analise-heading-xs.analise-bold.pb-2(style="color: #005A73; font-size: 19px") Submissão {{ index+1 }}
              .py-5
                v-row(no-gutters justify="space-between" align="center")
                  v-col(cols="auto")
                    .analise-subtitle-xs Enviada em
                  v-col(cols="auto")
                    .analise-subtitle-sm.analise-bold.black--text
                      span(v-if="!loading") {{ response.date }}
                      v-skeleton-loader(v-if="loading" type="text" width="90px")
                v-row(no-gutters justify="space-between" align="center" v-if="response.grade")
                  v-col(cols="auto")
                    .analise-subtitle-xs Nota
                  v-col(cols="auto")
                    .analise-subtitle-sm.analise-bold.black--text
                      span(v-if="!loading") {{ response.grade }}
                      v-skeleton-loader(v-if="loading" type="text" width="90px")
                br
                v-row(no-gutters justify="space-between" align="center")
                  v-col(cols="auto")
                    .analise-subtitle-xs Nota
                  v-col(cols="auto")
                    .analise-subtitle-sm.analise-bold.black--text
                      span(v-if="!loading") {{ response.general_grade }}
                      v-skeleton-loader(v-if="loading" type="text" width="90px")
              v-list-item(@click.stop="$router.push(`/activity/${id}/feedback`)")
                v-btn.mt-1.analise-bold(color="#2D848E" large block elevation=0 outlined)
                  v-list-item-icon.mr-2
                    Icon(width="24px" icon="ic:outline-info")
                    class.pa-1 Mais detalhes

      v-col(cols=12 class="pa-0")
        v-card.hidden-md-and-up(elevation=0 rounded="xl" dense style="background-color: #F2FCFF")
          .pa-6
            v-chip(label small color="#F2FCFF")
              .analise-bold(style="color: #005A73") Pendente
            .py-5
              v-row(no-gutters justify="space-between" align="center")
                v-col(cols="auto")
                  .analise-subtitle-xs Data de entrega
                v-col(cols="10" sm="9" xs="8")
                  .analise-subtitle-sm.analise-bold.black--text
                    span(v-if="!loading" class="font-weight-bold" style="position: absolut") {{ activity.date }}
                    v-skeleton-loader(v-if="loading" type="text" width="90px")
            v-divider
            v-menu(offset-y rounded="lg")
              template(v-slot:activator="{ on, attrs }")
                v-btn.white--text.mt-5(color="#2D848E" large block elevation=0 v-on="on" v-bind="attrs") Iniciar atividade
              v-list.pa-3
                v-list-item(@click.stop="$router.push(`/activity/${$route.params.id}/submit/write`)")
                  v-list-item-icon.mr-2
                    Icon(icon="ic:outline-create"  color="black" width="20px")
                  v-list-item-title Digitar redação
                //- v-list-item(@click.stop="$router.push(`/activity/${$route.params.id}/upload`)")
                //-   v-list-item-icon.mr-2
                //-     Icon(icon="ic:outline-upload"  color="black" width="20px")
                //-   v-list-item-title Enviar manuscrito                    
                
</template>

<script>
import { mapGetters } from 'vuex'
import Services from '../../services/services'

export default {
  data() {
    return {
      loading: true,
      response_activity: [],
      response_id: [],
      act: '',
      id: '',
      idActivity: [],
      infoProfile: [],
    }
  },
  methods: {
    translateAnswerStatus(status) {
      switch (status) {
        case 'pending':
          return 'Aguardando correção'
        default:
          return null
      }
    },
    
    async list_response_activity() {
      this.response_activity = await Services().activies_submite_list()
      for(let act in this.response_activity) {
        if (act.activie_id == this.id){
          this.response_id.push(act)
        }
      }
    }
  },
  computed: {
    ...mapGetters(),
    activity() {
      const id = this.$route.params.id
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.id = id
      const activity = this.$store.getters['activities/getActivityById'](id)
      return activity || {}
    }
  },
  async created() {
    this.loading = true
    const id = this.$route.params.id

    await this.$store.dispatch('activities/list/' +id+ '/')
    
    await this.list_response_activity()
    
    this.loading = false
  },
  async mounted() {
    this.infoProfile = await Services().profile()
    this.id = this.$route.params.id
    // await Services().activity()
    // this.idActivity = this.response_activity['']
    await this.list_response_activity()
  }
}
</script>