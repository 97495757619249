<template lang="pug">
.grid
  v-row(v-if="loading" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); font-size: 32px;",)
      v-progress-circular(
      :size="70",
      :width="7",
      color="#005A73",     
      indeterminate
)

</template>

<script>
// import http from '../http'
import Services from '../services/services'
// import axios from 'axios'
// import { config } from "process"

export default {
name: 'Logout',
data: () => ({
    loading: true
  }),

methods: {
async removeToken() {
  const accessToken = window.localStorage.getItem('accessToken')
  const refreshToken = window.localStorage.getItem('refreshToken')
  this.user = await Services().logout({'refresh':refreshToken}, {'headers':{'Authorization': 'Bearer ' + accessToken}})

}
      
},
created() {    
  this.removeToken() 
},

}
</script>

<style lang="sass" scoped>

</style>  