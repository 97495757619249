import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#07A5CE',
        secondary: '#4EBECA',
        success: '#40B93C',
        error: '#FF5252'
      }
    },
    options: {
      customProperties: true
    }
  }
});
